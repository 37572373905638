import Credit from '../../assets/credit.png';
import Debit from '../../assets/debit.png';
import BVN from '../../assets/bvnvalidation.png';
import CardFree from '../../assets/cardFree.png';
import SingleView from '../../assets/singleview.png';
import Payment from '../../assets/payment.png';
import './solutions.css';
import { HashLink } from 'react-router-hash-link';


function Solutions() {  
  return (
    <section className="solutions" id="solutions">
      <div className="section-header">
        <h1>Tailor-made solutions</h1>
        <p>PayThru™ offers various payment solutions for individuals, small and large scale businesses for smart, efficient and reliable fund disbursement and collection.</p>
      </div>
      <div className="solutions-wrapper">
        <HashLink
          to="/onboard#header"
          className="solution"
          smooth
        >
          <img src={CardFree} alt="Card Free" />
          <h2>PayThru™ CardFree</h2>
          <p>Secured and reliable payment gateway built with simplicty in mind. Recieve payment from your customers in any app in a few simple steps</p>
          <button>Get Started</button>
        </HashLink>
        <HashLink
          to="/payments#header"
          className="solution"
          smooth
        >
          <div>
            <img src={Payment} alt="Payment" />
            <h2>Payment</h2>
          </div>
          <p>Receive payment like pension directly with our Direct Payment solution.</p>
          <button>Get Started</button>
        </HashLink>
        <a href="http://apps.paythru.ng/Accounts/onboarding" target="_blank" rel="noreferrer" className="solution">
          <img src={Credit} alt="Credit" />
          <h2>PayThru™ Credit</h2>
          <p>Whatever your payment needs are, PayThru™ Credit offers you robust and secure bulk solution for salaries, pension contributions, etc.</p>
          <button>Get Started</button>
        </a>
        <a href="http://apps.paythru.ng/debit/default" className="solution">
          <img src={Debit} alt="Debit" />
          <h2>PayThru™ Debit</h2>
          <p>Never chase a customer for payment anymore with PayThru™ Debit, a robust Direct Debit Management system for all your business need.</p>
          <button>Get Started</button>
        </a>
        {/* <a href="http://apps.paythru.ng/Accounts/onboarding" target="_blank" rel="noreferrer"  className="solution">
          <img src={BVN} alt="BVN validation" />
          <h2>BVN Validation</h2>
          <p>Verify your customer information conveniently using our BVN validation service.</p>
          <button>Get Started</button>
        </a> */}
        <a href="http://apps.paythru.ng/Nqr" className="solution">
          <img src={Credit} alt="NQR" />
          <h2>PayThru™ NQR</h2>
          <p>We empower businesses to get started with the new NQR technology by NIBSS for contactless payment.</p>
          <button>Get Started</button>
        </a>
        {/* <a href="http://apps.paythru.ng/Accounts/onboarding" target="_blank" rel="noreferrer"  className="solution">
          <div>
            <img src={SingleView} alt="Single View" />
            <h2>Single View</h2>
          </div>
          <p>View all your accounts balance from all your banks in real time on a single page.</p>
          <button>Get Started</button>
        </a> */}
      </div>
    </section>
  );
}

export default Solutions;
