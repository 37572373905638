import { MultiSelect } from "react-multi-select-component";
import './index.scss';

export const CustomMultiSelect = ({
  data,
  value,
  ...props
}) => {

  return (
    <div className={`input-wrapper ${props.className}`}>
      <div className='label-wrapper'>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
      </div>
      <MultiSelect
        options={data}
        value={value}
        onChange={(val) => props.onChange(props.name, val)}
        className='multi-select'
        hasSelectAll={false}
        disableSearch={true}
        valueRenderer={(selected, _options) => {
          return selected.length
            ? selected.map(({ label }) => <div className="custom-value">{label}</div>)
            : "No Items Selected";
        }}
      />
      {props.hasError && <p>{props.errorText}</p>}
    </div>
  )
}