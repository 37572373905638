import axios from 'axios';
import { useCallback, useRef, useState, useEffect } from 'react';
import {ExcelRenderer} from 'react-excel-renderer';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Button from '../../Button';
import Input, {FileInput, ReactSelect, SelectComponent} from '../../Input';
import { ExcelDisplay } from '../../Excel';
import Utils from '../../../Utils';
import Close from '../../../assets/close.png';

import './pensions.scss';
import renderToast from '../../Toast';
import PFAsModal from '../../PFAsModal';
import { getService, postService } from '../../../services';
import SummaryModal, { Summary } from '../../SummaryModal';

const getYears = () => {
  const now = new Date().getUTCFullYear();
  return Array(now - (now - 20)).fill('').map((v, idx) => now - idx);
}
const entryModes = ['Single Entry', 'Multiple Entry'];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const years = getYears();

const PensionForm = () => {
  const [openSummary, setOpenSummary] = useState(false);
  const [currentSheet, setCurrentSheet] = useState({});
  const [showExcelDisplay, setShowExcelDisplay] = useState(false);
  const excelError = useRef({});
  const [fileKey, setFileKey] = useState(1);
  const currency = '₦';
  const url = `${Utils.providers.endpointProviderFor("Pension")}api/v1/core/collection/`;
  // const url = `http://sandbox.paythru.ng/api/v1/core/collection/`;
  const apiKey = `Paythru ${process.env.REACT_APP_API_KEY}`;
  const [step, setStep] = useState(0);
  const [response, setResponse] = useState({});
  const [PFAs, setPFAs] = useState(null);
  const [showPFAs, setShowPFAs] = useState(false);
  const [data, setData] = useState({
    employerCode: '',
    employerName: '',
    schedule: '',
    entryMode: entryModes[0],
    period: '',
    emailAddress: '',
    pfaCode: '',
    month: 'Jan',
    year: '2022',
    rsapin: '',
    staffId: '',
    employeeName: '',
    normalContributionEmployee: '',
    voluntaryContributionEmployee: '',
    normalContributionEmployer: '',
    voluntaryContributionEmployer: '',
    filteredDataArrObj: [],
    beneficiaries: [],
    duplicates: [],
    rsapinMap: {},
    paymentOption: 'Offline',
  });

  const isSingleEntry = data.entryMode === entryModes[0];

  const getPFAs = (buttonClicked = false) => {
    getService(
      `${url}pension/pfa`, 
      (data) => {
        setPFAs(data.data);
        buttonClicked && setShowPFAs(!showPFAs);
      },
      'PFAs Loaded Successfully!',
      'Error Loading PFAs!',
      'Getting supported PFAs...', 
    );
  }

  useEffect(() => {
    if ((step === 3) && isSingleEntry && (!PFAs || !PFAs.length)) {
      getPFAs();
    }
  }, [step, isSingleEntry]);

  const fileUploaderRef = useRef(null);

  const setExcelError = (val) => {
    excelError.current = val;
  }

  const submitScheduleDetails = (event) => {
    event.preventDefault();
    setStep(step + 1);
  }

  const dismissToast = () => {
    toast.dismiss();
  }

  const handleNext = () => {
    setStep(step - 1)
  };

  const validateEmployer = async (event) => {
    event.preventDefault();
    getService(
      `${url}pension/empvalidation/${data.employerCode}`,
      (data) => {
        setData((prev) => ({
          ...prev,
          employerName: data?.employerInfo?.name
        }));
        setStep(1);
      },
      'Employer validated succesfully!',
      'Error validating employer!',
      'Getting employer details...'
    )
  };

  const confirmEmployer = () => {
    dismissToast();
    setStep(2);
  }

  const getPeriod = () => {
    return `${data.month.toUpperCase()} ${data.year}`;
  }

  const submitData = async (event) => {
    event.preventDefault();
    let payload;
    if(isSingleEntry) {
      if (!data.pfaCode) {
        toast.error("Please select a PFA to continue!")
        return;
      }
      payload = {
        period: getPeriod(),
        employerCode: data.employerCode,
        beneficiaries: [
          {
            employeeName: data.employeeName,
            normalContributionEmployee: data.normalContributionEmployee,
            voluntaryContributionEmployee: data.voluntaryContributionEmployee,
            normalContributionEmployer: data.normalContributionEmployer,
            voluntaryContributionEmployer: data.voluntaryContributionEmployer,
            pfaCode: PFAs.find((pfa) => (pfa.pfaName === data.pfaCode.value))?.pfaCode,
            rsapin: data.rsapin,
            staffId: data.staffId
        }],
        emailAddress: data.emailAddress,
        saveBeneficiaries: false,
        saveBeneficiaryAs: '',
        paymentOption: data.paymentOption === 'Offline' ? 2 : 1,
      }
    } else {
      if (!data.schedule) {
        toast.error("Please select a file to upload!")
        return;
      }
      payload = {
        period: getPeriod(),
        employerCode: data.employerCode,
        beneficiaries: data.beneficiaries,
        emailAddress: data.emailAddress,
        saveBeneficiaries: false,
        paymentOption: data.paymentOption === 'Offline' ? 2 : 1,
      }
    }
    const newPayment = axios.post(`${url}pension/newpayment`, payload, {
      headers: {
        'Authorization': apiKey
      }
    });
    toast.promise(
      newPayment,
      {
        loading: 'Uploading your schedule...',
        success: (resp) => {
          if (!resp.data.successful) {
            throw new Error(resp.data.message);
          }
          setResponse(resp.data);
          setStep(4);
          return 'Schedule uploaded succesfully!'
        },
        error: (error) => {
          return (
            <div className='toast-close-btn'>
              {error.toString() || 'Error uploading schedule!'}
              <button onClick={() => toast.dismiss()}>x</button>
            </div>
          )
        },
        },
        {
        style: {
          fontSize: '20px',
          minWidth: '250px',
          fontWeight: 'bolder',
        },
        duration: 15000
      }
    );
    // postService(
    //   `${url}pension/newpayment`, 
    //   payload, 
    //   (data) => {
    //     setResponse(data);
    //     setStep(4);
    //   },
    //   'Schedule uploaded succesfully!',
    //   'Error uploading schedule!',
    //   'Uploading your schedule...',
    // )
  }

  const handleCancelExcelPreview = () => {
    setShowExcelDisplay(false);
    setData((prevState) => ({
      ...prevState,
      schedule: '',
    }));
    setFileKey(Date.now());
    setCurrentSheet({});
  }

  const validateHeader = (header) => {
    return (header.employeeName === 'EmployeeName') ||
      (header.normalContributionEmployee === 'NormalContributionEmployee') ||
      (header.voluntaryContributionEmployee === 'VoluntaryContributionEmployee') ||
      (header.normalContributionEmployer === 'NormalContributionEmployer') ||
      (header.voluntaryContributionEmployer === 'VoluntaryContributionEmployer') ||
      (header.pfaCode) === 'PfaCode' ||
      (header.rsapin) === 'Rsapin' ||
      (header.staffId) === 'StaffId';
  }

  const fileHandler = (fileObj) => {
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        toast.error('Error displaying Excel file!');            
      } else {
        const filteredDataArrObj = [];
        const duplicates = [];
        const rsapinMap = {};
        const sheetData = resp.rows;
        const len = sheetData.length;
        for (let a = 0; a < len; a++) {
          const entry = sheetData[a];
          if (entry.length > 0) {
            const obj = {
              employeeName: entry[0],
              normalContributionEmployee: entry[1],
              voluntaryContributionEmployee: entry[2],
              normalContributionEmployer: entry[3],
              voluntaryContributionEmployer: entry[4],
              pfaCode: entry[5],
              rsapin: entry[6],
              staffId: entry[7],
              __id: a,
            }
            filteredDataArrObj.push(obj);
            if (rsapinMap[entry[6]]) {
              rsapinMap[entry[6]] = rsapinMap[entry[6]] + 1;
              duplicates.push(entry[6]);
            } else {
              rsapinMap[entry[6]] = 1;
            }
          } else {
            break;
          }
        }
        const isHeaderValid = validateHeader(filteredDataArrObj[0]);
        if (!isHeaderValid) {
          renderToast('Invalid excel file uploaded, download the excel template to see expected format!', 'error', 30000);
          return;
        }
        if (filteredDataArrObj.length === 1) {
          renderToast('Empty Excel file uploaded!', 'error', 30000);
          return;
        }
        setData((prevState) => ({
          ...prevState,
          rsapinMap,
          duplicates: Array.from(new Set(duplicates)),
          filteredDataArrObj,
        }));
      }
    });               
  }

  const handleDataChange = useCallback((field, value) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value
    }));
    if (field === 'schedule') {
      setShowExcelDisplay(true);
      fileHandler(value);
    }
  }, []);

  const handleRemoveError = (__id) => {
    const newErrors = excelError.current;
    delete newErrors[__id];
    setExcelError(newErrors);
  }

  const removeExcelRow = (toDelete, callBack) => {
    const newData = [...data.filteredDataArrObj].filter((item) => item.__id !== toDelete.__id)
    setData((prevState) => ({
      ...prevState,
      filteredDataArrObj: newData,
    }));
    callBack();
    manageRSAPIN(toDelete.rsapin, 'delete');
    handleRemoveError(toDelete.__id);
    toast.success('Row Removed!');
  }

  const editExcelRow = (dataToAdd, prevData, callBack) => {
    const newData = [...data.filteredDataArrObj].map((item, itemIndex) => {
      if (item.__id === prevData.__id) {
        return dataToAdd;
      }
      return item;
    });
    setData((prevState) => ({
      ...prevState,
      filteredDataArrObj: newData,
    }));
    manageRSAPIN(dataToAdd.rsapin, 'edit', prevData.rsapin);
    handleRemoveError(dataToAdd.__id);
    callBack();
  }

  const addNewRow = (dataToAdd, callBack) => {
    const newData = [...data.filteredDataArrObj];
    newData.push(dataToAdd);
    setData((prevState) => ({
      ...prevState,
      filteredDataArrObj: newData,
    }));
    manageRSAPIN(dataToAdd.rsapin, 'add');
    callBack();
  }

  const setPaymentStatusKey = () => {
    localStorage.setItem('successKey', response.successIndicator);
    localStorage.setItem('transactionID', response.scheduleId);
    localStorage.setItem('employerCode', data.employerCode);
  }

  const handlePreviewDone = () => {
    setData((prevState) => ({
      ...prevState,
      beneficiaries: data.filteredDataArrObj.slice(1)
    }));
    setShowExcelDisplay(false);
  }

  const manageRSAPIN = (pin, actionType, prevPin) => {
    switch (actionType) {
      case 'delete':
        if (data.rsapinMap[pin] > 1) {
          data.rsapinMap[pin] = data.rsapinMap[pin] - 1;
        }
        if (data.rsapinMap[pin] === 1) {
          delete data.rsapinMap[pin];
        }
        return;
      case 'edit':  
        if (data.rsapinMap[prevPin] > 1) {
          data.rsapinMap[prevPin] = data.rsapinMap[prevPin] - 1;
        }
        if (!data.rsapinMap[pin]) {
          data.rsapinMap[pin] = 1;
        }
        if (data.rsapinMap[prevPin] === 1 && pin !== prevPin && !data.rsapinMap[pin]) {
          delete data.rsapinMap[prevPin];
        }
        return;
      case 'add':  
        if (!data.rsapinMap[pin]) {
          data.rsapinMap[pin] = 1;
        }
        return;
      default:
        break;
    }
  };

  const isOffline = data.paymentOption === 'Offline';

  const renderViewPFAs = () => {
    return (
      <Button
        className="contained smBtn"
        onClick={() => {
          if (!PFAs || !PFAs.length) {
            getPFAs(true);
          } else {
            setShowPFAs(!showPFAs);
          }
        }}
        label="View PFAs"
      />
    );
  };

  return (
    <div className='pension-form'>
      <div className="form">
        {
          step === 0 && (
            <form className="form-wrapper" onSubmit={validateEmployer}>
              <p>Employer Details</p>
              <div className="form-group">
                <Input
                  label="Employer Code"
                  className="select"
                  name="employerCode"
                  required
                  value={data.employerCode}
                  onChange={handleDataChange}
                />
              </div>
              <div className="form-group btn-wrapper">
                <Button btnActionType="submit" label="Submit" className="input submitBtn" />
              </div>
            </form>
          )
        }
        {
          step === 1 && (
            <div className="form-wrapper">
              <p>Please Confirm Employer Details</p>
              <div className="form-group details-wrapper">
                <p className='title'>Employer Name</p>
                <p className='value'>{data.employerName}</p>
              </div>
              <div className="form-group details-wrapper">
                <p className='title'>Employer Code</p>
                <p className='value'>{data.employerCode}</p>
              </div>
              <div className="form-group btn-wrapper">
                <Button onClick={handleNext} label="Go Back" className="input" />
                <Button onClick={confirmEmployer} label="Confirm" className="input submitBtn" />
              </div>
            </div>
          )
        }
        {
          step === 2 && (
            <form className="form-wrapper" onSubmit={submitScheduleDetails}>
              <div className='single-entry-title'>
                <p>Schedule Details</p>
                {renderViewPFAs()}
              </div>
              <div className="form-group">
                <Input
                  label="Email"
                  className="select"
                  name="emailAddress"
                  required
                  value={data.emailAddress}
                  onChange={handleDataChange}
                  type="email"
                />
              </div>
              <div className="form-group">
                <SelectComponent
                  label="Period Year"
                  className="input"
                  name="year"
                  required
                  value={data.year}
                  onChange={handleDataChange}
                  data={years}
                />
               <SelectComponent
                  label="Period Month"
                  className="input"
                  name="month"
                  required
                  value={data.month}
                  onChange={handleDataChange}
                  data={months}
                />
              </div>
              <div className="form-group">
                <SelectComponent
                  label="What is your Entry Mode?"
                  className="select"
                  name="entryMode"
                  required
                  value={data.entryMode}
                  onChange={handleDataChange}
                  data={entryModes}
                />
              </div>
              <div className="form-group">
                <SelectComponent
                  label="What is your prefered payment mode?"
                  className="select"
                  name="paymentOption"
                  required
                  value={data.paymentOption}
                  onChange={handleDataChange}
                  data={['Offline', 'Online']}
                />
              </div>
              <div className="form-group btn-wrapper">
                <Button onClick={handleNext} label="Go Back" className="input" />
                <Button btnActionType="submit" label="Next" className="input submitBtn" />
              </div>
            </form>
          )
        }
        {
          step === 3 && (
            <form className="form-wrapper" onSubmit={submitData}>
              <div className='single-entry-title'>
                <p>Add Beneficiaries</p>
                {
                  renderViewPFAs()
                }
              </div>
              {
                isSingleEntry ? (
                  <fieldset>
                    <div className="form-group">
                      <Input
                        label="Employee Name"
                        className="input"
                        name="employeeName"
                        required
                        value={data.employeeName}
                        onChange={handleDataChange}
                      />
                      <ReactSelect
                        label="PFA"
                        className="input"
                        name="pfaCode"
                        renderOptions={PFAs?.map((item) => (
                          {
                            label: `${item?.pfaName}`, 
                            value: `${item?.pfaName}`
                          }))
                        }
                        onChange={handleDataChange}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        type="number"
                        label="Employee NC"
                        className="input"
                        name="normalContributionEmployee"
                        required
                        value={data.normalContributionEmployee}
                        onChange={handleDataChange}
                      />
                      <Input
                        type="number"
                        label="Employee VC"
                        className="input"
                        name="voluntaryContributionEmployee"
                        required
                        value={data.voluntaryContributionEmployee}
                        onChange={handleDataChange}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        type="number"
                        label="Employer NC"
                        className="input"
                        name="normalContributionEmployer"
                        required
                        value={data.normalContributionEmployer}
                        onChange={handleDataChange}
                      />
                      <Input
                        type="number"
                        label="Employer VC"
                        className="input"
                        name="voluntaryContributionEmployer"
                        required
                        value={data.voluntaryContributionEmployer}
                        onChange={handleDataChange}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        label="RSA PIN"
                        className="input"
                        name="rsapin"
                        required
                        pattern="([0-9a-zA-z]){15}"
                        placeholder='PEN456789012345'
                        value={data.rsapin}
                        onChange={handleDataChange}
                      />
                      <Input
                        label="Staff Id"
                        className="input"
                        name="staffId"
                        required
                        value={data.staffId}
                        onChange={handleDataChange}
                      />
                    </div>
                    <div className='note'>
                      <b>NC: Normal Contribution</b>
                      <b>VC: Voluntary Contribution</b>
                    </div>
                  </fieldset>
                ) : (
                  <>
                    <div className="form-group">
                      <FileInput
                        label="Schedule Form"
                        className="select"
                        name="schedule"
                        type="file"
                        useRef={fileUploaderRef}
                        value={data.schedule}
                        accept=".xlsx, .xls, .csv"
                        onChange={handleDataChange}
                        key={fileKey}
                      />
                    </div>
                    <div className='link-wrapper'>
                      <p className='download-link'>Click <Link to="/templates/Epccos_Template.xlsx" target="_blank" download>here</Link> to download file template</p>
                      {data.schedule ? <b onClick={() => setShowExcelDisplay(true)}>Preview File</b> : null}
                    </div>
                  </>
                )
              }
              <div className="form-group btn-wrapper small-top">
                <Button onClick={handleNext} label="Go Back" className="input" />
                <Button btnActionType="submit" label="Submit" className="input submitBtn" />
              </div>
            </form>
          )
        }
        {
          step === 4 && (
            <div className="form-wrapper">
              <p>Schedule Details</p>
              <div className="form-group">
                <div className="form-group details-wrapper">
                  <p className='title'>Schedule Id</p>
                  <p className='value'>{response.scheduleId}</p>
                </div>
                <div className="form-group details-wrapper">
                  <p className='title'>Code</p>
                  <p className='value'>{response.code}</p>
                </div>
              </div>
              <div className='pension-sub-group'>
                <div className='single-entry-title'>
                  <p>Summary</p>
                  {(response?.summary && response.summary.length > 3) && window.innerWidth > 768 && <span onClick={() => setOpenSummary(true)}>View all Summaries.</span>}
                </div>
                {
                  isOffline ? (
                    <div className='summary-wrapper'>
                      {
                        response?.summary && response?.summary.map((item, index) => (
                          <Summary item={item} index={index} />
                        ))
                      }
                    </div>
                  ) : (
                    <div className='summary-wrapper'>
                      {
                        response?.summary && response?.summary.map((item, index) => (
                          <div key={index} className="summary">
                            <p>{item.pfaName}</p>
                            <div className='summ-detail'>
                              <span>Total Employee: <b>{item.totalEmployee}</b></span>
                              <span>Total Amount: <b>{currency}{item.total}</b></span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                }
              </div>
              <div className="form-group">
                <div className="form-group details-wrapper">
                  <p className='title'>Service Charge</p>
                  <p className='value'>{currency}{response.serviceCharge}</p>
                </div>
                <div className="form-group details-wrapper">
                  <p className='title'>Total Amount</p>
                  <p className='value'>{currency}{response.totalAmount}</p>
                </div>
              </div>
              {
                response.paymentDetails && (
                  <div className='pension-sub-group'>
                    <p>Payment Details</p>
                    <div>
                      <span>Bank Name: <b>{response?.paymentDetails?.bankName}</b></span>
                      <span>Account Name: <b>{response?.paymentDetails?.accountName}</b></span>
                      <span>Account Number: <b>{response?.paymentDetails?.accountNumber}</b></span>
                    </div>
                  </div>
                )
              }
              <div className="form-group btn-wrapper">
                {/* <Button onClick={handleNext} label="Cancel" className="input" /> */}
                {
                  !isOffline && (
                    <a href={response.payLink} target="_self" rel="noopener noreferrer" className="select" style={{width: '100%'}}>
                      <Button
                        onClick={() => setPaymentStatusKey()}
                        label={"Make Payment"} 
                        type="button" 
                        className="submitBtn lgBtn"
                      />
                    </a>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
      {
        showExcelDisplay && (
          <ExcelDisplay
            currentSheet={currentSheet}
            setCurrentSheet={setCurrentSheet}
            filteredDataArrObj={data.filteredDataArrObj}
            setShowExcelDisplay={setShowExcelDisplay}
            handleCancelExcelPreview={handleCancelExcelPreview}
            removeExcelRow={removeExcelRow}
            editExcelRow={editExcelRow}
            addNewRow={addNewRow}
            handlePreviewDone={handlePreviewDone}
            rsapinMap={data.rsapinMap}
            excelError={excelError}
            setExcelError={setExcelError}
          />
        )
      }
      {
        showPFAs && (
          <PFAsModal pfas={PFAs} closeModal={setShowPFAs} />
        )
      }
      {
        openSummary && (
          <SummaryModal summaries={response?.summary || []} closeModal={setOpenSummary} />
        )
      }
      <PreviousDownload data={data} />
    </div>
  );
};

export default PensionForm;

const PreviousDownload = ({data}) => {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div className='previous-download'>
      {
        showHelp && (
          <div className='help'>
            <div className='underlay' onClick={() => setShowHelp(false)} />
            <div className='content'>
              <button className='cls-btn' onClick={() => setShowHelp(false)}>
                <img src={Close} alt="" />
              </button>
              <h2>Need help using our platform?</h2>
              <p>Kindly reachout to us by phone or email</p>
              <h4>+2348026603738</h4>
              <h4>
                <a href="mailto:ptrpensioncollections@pethahiah.com?subject=Pension Help">
                  ptrpensioncollections@pethahiah.com
                </a>
              </h4>
            </div>
          </div>
        )
      }
      <Link to={`/pension/receipt/download/${data.employerCode}`}>
        Download Receipt for a previous payment.
      </Link>
      <Button className="text" label="Need Help?" onClick={() => setShowHelp(true)} />
    </div>
  )
}
