import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../assets/paythruLogo.png';

const currency = 'NGA';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '20px',
    margin: 20,
    padding: 10,
    position: 'relative',
  },
  logo: {
    width: 100,
    height: 30,
  },
  body: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },
  bodyItem: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '70%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
  },
  bookmark: {
    objectFit: 'contain',
    position: 'absolute',
    opacity: 0.1,
    height: '100%',
    width: '70%',
    marginLeft: 'auto',
    left: '15%',
    top: '10%'
  },
  container: {
    position: 'relative',
  },
  value: {
    width: '60%',
    textAlign: 'right',
    fontSize: '12px',
  }
});

// Create Document Component
const Receipt = ({data}) => {
  const getDate = (date) => {
    return date?.split('.')[0]?.split('T').join(' ');
  }
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.container}>
          <Image src={Logo} style={styles.bookmark} />
          <View style={styles.section}>
            <Image src={Logo} style={styles.logo} />
            <Text style={{fontWeight: '900', fontSize: '22px'}}>Payment Receipt</Text>
          </View>
          <View style={styles.body}>
            <Text>{data.data.description}</Text>
          </View>
          <View style={styles.body}>
            {
              data.data.transactionDate && (
                <View style={styles.bodyItem}>
                  <Text>Date </Text>
                  <Text style={styles.value}>{getDate(data?.data?.transactionDate)}</Text>
                </View>
              )
            }
            <View style={styles.bodyItem}>
              <Text>Employer Code</Text>
              <Text style={styles.value}>{data.payData.employerCode}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Transaction ID</Text>
              <Text style={styles.value}>{data.data.transactionId}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Total Employees</Text>
              <Text style={styles.value}>{data.data.totalEmployees}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Schedule Status</Text>
              <Text style={styles.value}>{data.data.scheduleStatus}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Processed Date</Text>
              <Text style={styles.value}>{getDate(data.data.processedDate)}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Payment Date</Text>
              <Text style={styles.value}>{getDate(data.data.paymentDate)}</Text>
            </View>
            <View style={[styles.bodyItem, {marginTop: 30}]}>
              <Text>Transaction Amount</Text>
              <Text style={styles.value}>{currency} {data.data.transactionAmount}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Charges</Text>
              <Text style={styles.value}>{currency} {data.data.charges}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Total</Text>
              <Text style={{
                borderTop: '1px solid #000', 
                borderBottom: '1px solid #000', 
                width: 100, 
                paddingTop: 5, 
                paddingBottom: 5, 
                textAlign: 'right'
                }}
              >{currency} {`${data.data.total}`}</Text>
            </View>
          </View>
          <View style={[styles.bodyItem, {
            margin: 'auto',
            marginTop: '100px',
          }]}>
            <Text
              style={{ 
                fontSize: '10px', 
                width: '80%', 
                textAlign: 'center', 
                margin: 'auto',
              }}
            >
              Additional charges on the payment gateway are not shown on this receipt.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export default Receipt;
