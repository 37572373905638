import FormBuilder from "../../../../../../components/Formbuilder";

const CreateBanks = () => {
  return (
    <div className="add-bank-wrapper">
        <FormBuilder
            state={{
              bank: '',
              accountNumber: '',
              accountName: '',
            }}
            showSteps
            data={[
              {
                title: 'Enter Your Bank details',
                subTitle: '',
                formItems: [
                  {
                    label: 'Select Bank',
                    name: 'bank',
                    type: 'searchAble',
                    errorText: 'Error Text',
                    data: ['UBA', 'GT Bank'],
                    required: true,
                  },
                  {
                    label: 'Account Number',
                    name: 'snumber',
                    type: 'text',
                    errorText: 'Error Text',
                    data: [],
                  },
                  {
                    label: 'Account Name',
                    name: 'mnumber',
                    type: 'text',
                    errorText: 'Error Text',
                    data: [],
                    required: true,
                  }
                ]
              },
            ]}
          />
    </div>
  );
};

export default CreateBanks;
