const docs = {
  products: {
    cardFree: {
      name: 'Card Free',
      version: '1.3',
      sections: ['basics', 'specification', 'endpoints'],
      docs: {
        basics: {
          name: 'basics',
          documentation: [
            {
              title: 'introduction',
              description: [],
              conclusion: [],
              bodyType: 'paragraph',
              body: [
                'PayThruTM Card-Free is a robust and reliable payment gateway for everyday payment needs.',
                'Whether you run a small or large scale business, online or offline, there is a payment option for every of customers. Built with developers in mind, PayThruTM Card-Free offers seamless integration into your application in few simple steps.',
                'This document describes the integration procedures for seamless integration of our payment API into your existing or new applications'
              ]
            },
            {
              title: 'Getting Started',
              description: [],
              conclusion: [],
              code: '',
              bodyType: 'list',
              body: [
                'Visit https://paythru.ng and click on sign up on the navigation menu',
                'Complete the enrollment form and select product of your interest (Cardless) in this case and click on submit',
                'Confirm your email on the next page',
                'Your login details will be sent to your registered email address',
              ]
            },
            {
              title: 'Your Product',
              description: [
                'PayThruTM Card-Free runs on a context of product. This means as a merchant, you need to create at least one product on the platform as you are required to specify a product Id in your API calls for payment on your application. You do not need to create different products for each item in your store. Typically, one product is enough for even a supermarket. As long those products sold in the same shop location or online channel. The number of products you create depends on your requirements.',
                'Before you create a product, you need to specify your collection account. Your collection account is the account where your funds will be sent to during a settlement period. You can not change this account ones added. So, use the right accounts or contact PayThru for further modification.',
                'You can have multiple collection accounts as you can specify different collection accounts for each product.'
              ],
              conclusion: ['At this point, you have successfully created your PayThruTM Card-Free product and ready for integration into your application.'],
              code: '',
              bodyType: 'list',
              bodyHeadling: 'You can create a product by following the steps below',
              body: [
                'Login with your credentials',
                'Click on product under Cardless menu on the side bar menu',
                'Click add product on the next page',
                'Complete the product information and click on continue',
              ]
            },
            {
              title: 'Integration',
              description: [
                'Before you begin, please be note that PayThruTM allows you to go live instantly once you are ready, but your account will be limited to a certain volume until you complete all required KYC documentations.',
                'To complete your KYC, click on the KYC button on the sidebar menu to get started.',
                'The first thing you might want to do on this section is to click on the integration button on sidebar menu and generate your API credentials on the next page if you have not done so before.',
                'Please see the API specification section for information on using the APIs',
              ],
              conclusion: [],
              code: '',
              bodyType: null,
              bodyHeadling: '',
              body: []
            },
            {
              title: 'Go Live',
              description: [
                'Once you are satisfied with your development, you can use the go live button under Cardless on the sidebar menu to go live and start real transactions.',
              ],
              conclusion: [],
              code: '',
              bodyType: null,
              bodyHeadling: '',
              body: []
            }
          ]
        },
        specification: {
          name: 'specification',
          documentation: [
            {
              title: 'Introduction',
              description: ['This section provides the details for the API and endpoints you will need for the integration of the PaythruTM Card-Free into your application.'],
              conclusion: [],
              bodyType: '',
              body: []
            },
            {
              title: 'Getting Started',
              description: [
                'Test: https://sandbox.paythru.ng/cardfree',
                'Production: https://services.paythru.ng/cardfree'
              ],
              conclusion: [],
              code: '',
              bodyType: '',
              body: []
            },
            {
              title: 'API Endpoints',
              description: [],
              conclusion: [],
              code: '',
              bodyType: '',
              bodyHeadling: '',
              body: [],
              endpoints: [
                {
                  title: 'Create Transaction',
                  url: '[POST] transaction/create',
                  description: ['This endpoint creates a new transaction and return the redirect link for payment. After a successful call to this endpoint, you can redirect your customer to the returned link to complete their transaction'],
                  sampleRequest: {
                    "amount": 0,
                    "productId": "73252111639695000",
                    "transactionReference": "722533952428415899",
                    "paymentDescription": "Test payment",
                    "sign": "",
                    "paymentType": 4,
                    "displaySummary": true
                  },
                  requestDetails: [
                    {
                      name: 'Amount',
                      description: {
                        detail: 'Transaction Amount'
                      },
                      dataType: 'decimal',
                      remarks: 'optional'
                    },
                    {
                      name: 'ProductId',
                      description: {
                        detail: 'This is your product Id on PayThruTM Card-Free platform. Please refer to section one on creating your products'
                      },
                      dataType: 'string',
                      remarks: 'Mandatory'
                    },
                    {
                      name: 'TransactionReference',
                      description: {
                        detail: 'This is a unique merchant’s reference for this transaction.'
                      },
                      dataType: 'string',
                      remarks: 'Mandatory'
                    },
                    {
                      name: 'Payment Description',
                      description: {
                        detail: 'This is payment description which will appear on the customer bank statement for this transaction'
                      },
                      dataType: 'string',
                      remarks: 'optional'
                    },
                    {
                      name: 'Sign',
                      description: {
                        detail: 'SHA512 hash of transaction amount with the client secret. This is mandatory if payment type value is neither 3 or 4. Example: Amount: 2000 Secret: abcd123456 Sign = Sha512(2000abc123456)'
                      },
                      dataType: 'Sha512',
                      remarks: ''
                    },
                    {
                      name: 'Payment Type',
                      description: {
                        detail: 'SHA512 hash of transaction amount with the client secret. This is mandatory if payment type value is neither 3 or 4. Example: Amount: 2000 Secret: abcd123456 Sign = Sha512(2000abc123456)',
                        list: [
                          '1- Specifies that the transaction can only be completed by one user with the amount specified. In this case, the amount property is required. Use this option to accept payment from your customers in your application for product sales',
                          '2- Specifies that the transaction can be completed by multiple users with the amount specified each. In this case, the amount property is mandatory. Use this option for group contributions where each member of the group needs to contribute the same amount towards a goal',
                          '3- Specifies that the transaction can be completed by single user with a custom payment amount. In this case, the amount property is not required for this call',
                          '4- Specifies that the transaction can be completed by multiple users with custom payment amounts each. In this case, the amount property is not required. Useful for churches and other organizations for offerings, donations and other types of collections'
                        ]
                      },
                      dataType: 'Enum => 1 | 2 | 3 | 4',
                      remarks: 'Mandatory'
                    },
                    {
                      name: 'DisplaySummary',
                      description: {
                        detail: 'If this option is enabled for a transaction, the payer will see the total amount that has been collected after a successful payment. Use this option for donations where you want to expose the total amount garnered to the donors',
                        list: []
                      },
                      dataType: 'Boolean => False | True ',
                      remarks: 'Optional'
                    },
                  ],
                  sampleResponse: {
                    "SuccessIndicator": "aar66p2a283udm2k",
                    "PayLink": "https://paythru.ng/services/demo/pay/838742561649870280", "Code": 0,
                    "Message": "Successful",
                    "Data": null,
                    "Successful": true
                  },
                  responseDescription: [
                    {
                      name: 'Code',
                      description: {
                        detail: 'Response Code'
                      },
                      dataType: 'Int',
                      remarks: ''
                    },
                    {
                      name: 'Message',
                      description: {
                        detail: 'Response Description'
                      },
                      dataType: 'string',
                      remarks: ''
                    },
                    {
                      name: 'SuccessIndicator',
                      description: {
                        detail: 'The is a parameter to used to determine the outcome of this transaction'
                      },
                      dataType: 'string',
                      remarks: 'Reversed for future version'
                    },
                    {
                      name: 'PayLink',
                      description: {
                        detail: 'This is the payment link returned if the call for create transaction is successful.'
                      },
                      dataType: 'string',
                      remarks: ''
                    },
                    {
                      name: 'Successful',
                      description: {
                        detail: 'Indicates whether the call was successful or not. This will always be true when the response code is 0 and false for anything else. So you can use either Code or the Successful property to determine if the call was successful or not'
                      },
                      dataType: 'bool',
                      remarks: ''
                    },
                  ],
                },
                {
                  title: 'Transaction Status Request',
                  url: '[GET] Transaction/status/{referenceid}',
                  description: ['This endpoint returns the details of the transaction specified by the transaction reference.'],
                  sampleRequest: null,
                  requestDetails: null,
                  sampleResponse: {
                    "code": 0,
                    "message": "Successful",
                    "data": {
                      "productId": "",
                      "transactionDate": "2021-12-21T15:28:43.863255+01:00",
                      "merchantReference": "25367487596780",
                      "customerId": 1,
                      "fiName": "New bank ltd",
                      "paymentMethod": "USSD",
                      "linkId": null,
                      "transactionReference": "",
                      "paymentReference": "",
                      "fiTransactionId": "123456",
                      "responseCode": "00",
                      "responseDescription": "Successful",
                      "status": "Successful",
                      "completedOn": "2021-12-21T15:28:43.8602565+01:00",
                      "naration": "some payment",
                      "currency": "566",
                      "ussdReferenceCode": "",
                      "SuccessIndicator": "",
                      "transactionInformation": {
                        "status": 1,
                        "amount": 5000.0,
                        "commission": 100.0,
                        "customerName": "Abiodun Ikemefula",
                        "accountNumber": "",
                        "ResidualAmount": "4900.00",
                      }
                    },
                    "successful": true
                  },
                  responseDescription: [
                    {
                      name: 'ProductId',
                      description: {
                        detail: 'This is the product Id String used when the create transaction endpoint was called'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Transaction Date',
                      description: {
                        detail: 'Date of Transaction'
                      },
                      dataType: 'DateTime',
                      remarks: ''
                    },
                    {
                      name: 'Merchant Reference',
                      description: {
                        detail: 'A unique reference for the transaction on merchants system',
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Customer Id',
                      description: {
                        detail: 'This is PayThrus unique indentifier for the paying customer.'
                      },
                      dataType: 'Int',
                      remarks: ''
                    },
                    {
                      name: 'FIName',
                      description: {
                        detail: 'Name of the paying financial institution.'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Payment Method',
                      description: {
                        detail: 'Method of payment selected by customer.'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Link Id',
                      description: {
                        detail: 'The is the reference Id of the transaction link returned when create transaction endpoint.'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Transaction Reference',
                      description: {
                        detail: 'Unique reference for the transaction on PayThruTM system'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Payment Reference',
                      description: {
                        detail: 'Unique reference for this transaction on FI system'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'FI TransactionId',
                      description: {
                        detail: 'Unique identifier for the transaction on FI system. This and the above property might be same depending on the customers bank'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Response Code',
                      description: {
                        detail: 'Response code for the transaction from FI. 00 means successful. When this property is something else other than 00, check the status property'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Response Description',
                      description: {
                        detail: 'Description of response from FI'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Status',
                      description: {
                        detail: 'Transaction Status'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Completed On',
                      description: {
                        detail: 'Date transaction was completed'
                      },
                      dataType: 'DateTime',
                      remarks: ''
                    },
                    {
                      name: 'Completed On',
                      description: {
                        detail: 'Date transaction was completed'
                      },
                      dataType: 'DateTime',
                      remarks: ''
                    },
                    {
                      name: 'Naration',
                      description: {
                        detail: 'Transaction naration'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Currency',
                      description: {
                        detail: 'Currency Code for the transaction. 566 is Naira'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'SuccessIndicator',
                      description: {
                        detail: 'A value that indicates value returned when the outcome of the transaction. This should match the the transaction request was initiated'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'ResidualAmount',
                      description: {
                        detail: 'The settled amount decimal for this transaction where merchant is responsible for transaction charges'
                      },
                      dataType: 'decimal',
                      remarks: ''
                    },
                  ],
                },
                {
                  title: 'Webhook',
                  url: '',
                  description: [
                    'Where the business wishes to utilize the webhook notification feature, PayThru gateway will initiate a webhook to the merchant provided endpoint after the conclusion of a transaction.',
                    'It is expected that merchant return http 200 OK where response is successfully processed.',
                    ' Anything else will be considered as failure and callback will be requeued for processing again until the maximum attempt is reached.'
                  ],
                  sampleRequest: {
                    "productId": "1234567",
                    "transactionDate": "2022-04-13T20:31:01.1861619+01:00",
                    "merchantReference": "65247892",
                    "fiName": "Zenith bank",
                    "paymentMethod": "Account",
                    "payThruReference": "some value",
                    "paymentReference": "some value",
                    "responseCode": "00",
                    "responseDescription": "Successful",
                    "completedOn": "2022-04-13T20:31:01.183163+01:00",
                    "naration": "Ice cream",
                    "currency": "566",
                    "ussdReferenceCode": "some string",
                    "status": 1,
                    "amount": 10.0,
                    "commission": 1.0,
                    "residualAmount": 9.0,
                    "customerName": "Tunde",
                    "resultCode": "some value"
                  },
                  requestDetails: [
                    {
                      name: 'ProductId',
                      description: {
                        detail: 'Target product Id for the transaction'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Transaction Date',
                      description: {
                        detail: 'Date of Transaction'
                      },
                      dataType: 'DateTime',
                      remarks: ''
                    },
                    {
                      name: 'Merchant Reference',
                      description: {
                        detail: 'The transaction reference provided by the merchant.',
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'FIName',
                      description: {
                        detail: 'Customer bank name.'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Payment Method',
                      description: {
                        detail: 'Method of payment selected by customer.'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'PayThruReference',
                      description: {
                        detail: 'Transaction reference generated by PayThru for this transaction.'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Payment Reference',
                      description: {
                        detail: 'Payment reference for this transaction by customer FI.'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'ResponseCode',
                      description: {
                        detail: 'Transaction response from FI'
                      },
                      dataType: 'String',
                      remarks: '00 => successful'
                    },
                    {
                      name: 'ResultCode',
                      description: {
                        detail: 'This is a value used to determine the outcome of the transaction. It should SuccesIndicator returned when the transaction request was initiated'
                      },
                      dataType: 'String',
                      remarks: ''
                    },
                    {
                      name: 'Status',
                      description: {
                        detail: 'Transaction Status'
                      },
                      dataType: 'Enum',
                      remarks: '1: Successful | 2: Failed | 3: Pending'
                    },
                    {
                      name: 'ResidualAmount',
                      description: {
                        detail: 'The actual amount that goes to the merchant. Applicable to merchants that takes responsibility for transaction charges'
                      },
                      dataType: 'decimal',
                      remarks: ''
                    },
                  ],
                  headerDescription: [
                    {
                      name: 'ApplicationId',
                      description: {
                        detail: 'This is your application Id'
                      },
                      remarks: ''
                    },
                    {
                      name: 'Timestamp',
                      description: {
                        detail: 'Current timestamp in Unix format',
                      },
                      remarks: ''
                    },
                    {
                      name: 'Signature',
                      description: {
                        detail: 'This is SHA512 of the request time and client secret'
                      },
                      remarks: ''
                    },
                  ],
                }
              ]
            },
          ]
        },
        endpoints: {
          name: 'endpoints',
          documentation: [
            {
              title: 'Introduction',
              description: ['Every transaction on PayThru payment gateway is on a context of product. Product in this context does not refer to every item in merchant store. A product can represent a whole mall.',
                'This sections describes the api available for merchant to create product on PayThru platform.',
                'NB: This session requires to login and retrieve an authorization token to be included in the authorization subsequent request header with schema name Paythru',
              ],
              conclusion: [],
              bodyType: '',
              body: []
            },
            {
              title: '',
              description: [],
              conclusion: [],
              code: '',
              bodyType: '',
              bodyHeadling: '',
              body: [],
              endpoints: [
                {
                  title: 'Get Authorization Token',
                  url: '[POST] -> /identity/auth/login',
                  description: [],
                  sampleRequest: {
                    "applicationId": "string",
                    "password": "Sha256 has of client secret plus timestamp"
                  },
                  requestDetails: [],
                  sampleResponse: {
                    "responseCode": 0,
                    "message": "",
                    "data": "Token",
                  },
                  responseDescription: [],
                  headerDescription: [
                    {
                      name: 'Timestamp',
                      description: {
                        detail: 'Current timestamp in Unix format',
                      },
                      remarks: 'This must be current times stamp at the time of request'
                    },
                  ],
                },
                {
                  title: 'Create Product Request',
                  url: '[POST] -> products/create',
                  description: ['This endpoint is used to register a new product on PayThruTM CardFree payment gateway.'],
                  sampleRequest: {
                    "productName": "Universal Stores",
                    "isPacketBased": false,
                    "productDescription": "Universal Lagos mall",
                    "feeBearer": 2,
                    "settlementAccountId": 345,
                    "webHookUrl": null
                  },
                  requestDetails: [
                    {
                      name: 'ProductName',
                      description: {
                        detail: 'Name of the product. This can be a store name where merchant has multiple stores.'
                      },
                      dataType: 'String',
                      remarks: 'Mandatory'
                    },
                    {
                      name: 'IsPackgeBased',
                      description: {
                        detail: 'Specifies if the product will be configured with different packages.'
                      },
                      dataType: 'Boolean',
                      remarks: 'This parameter is not required and will default to false on the current version'
                    },
                    {
                      name: 'ProductDescription',
                      description: {
                        detail: 'Description of your product'
                      },
                      dataType: 'String',
                      remarks: 'Mandatory'
                    },
                    {
                      name: 'FeeBearer',
                      description: {
                        detail: 'Specifies who bears the transaction fees. Possible values are',
                        list: [
                          '1 - Customer',
                          '2 - Merchant'
                        ]
                      },
                      dataType: 'Int',
                      remarks: 'Mandatory'
                    },
                    {
                      name: 'SettlementAccountId',
                      description: {
                        detail: 'Specifies the collection account to be used for remittance for all payments received for this product.',
                      },
                      dataType: 'Int',
                      remarks: 'Mandatory'
                    },
                    {
                      name: 'WebhookUrl',
                      description: {
                        detail: 'Specifies the URL to be used for callback notification for this product. If this parameter is not provided, global callback for the merchant will be used.',
                      },
                      dataType: 'String',
                      remarks: 'Optional'
                    }
                  ],
                  sampleResponse: {
                    "responseCode": 0,
                    "description": ""
                  },
                  responseDescription: [],
                  headerDescription: null,
                },
                {
                  title: 'Product Details Request',
                  url: '[GET] -> Cardfree/Products/{id}',
                  description: ['This endpoint is used to get the details of the product using the product Id.'],
                  sampleRequest: null,
                  requestDetails: null,
                  sampleResponse: {
                    "productInfo": {
                      "productId": "28657839764",
                      "productName": "Universal Stores",
                      "isPacketBased": false,
                      "productDescription": "Universal Lagos Mall",
                      "dateCreated": "2022-05-11T00:04:16.1418086+01:00",
                      "dateActivated": "2022-05-11T00:04:16.1408075+01:00",
                      "feeBearer": "Business",
                      "productState": "Development",
                      "settlementAccountId": 345,
                      "productPackages": null,
                      "webHookUrl": "",
                      "settlementAccount": {
                        "id": 345,
                        "accountStatus": "Active",
                        "bankCode": "000",
                        "accountNumber": "0123456789",
                        "accountName": "Universal Nigeria Plc",
                        "bankName": "Future Nigeria bank"
                      }
                    },
                    "responseCode": 0,
                    "description": ""
                  },
                  responseDescription: [],
                  headerDescription: null,
                },
                {
                  title: 'Get All Products',
                  url: '[GET] -> Cardfree/Products/list',
                  description: ['Merchants can use this endpoint to list of all products available on their account.'],
                  sampleRequest: null,
                  requestDetails: null,
                  sampleResponse: {
                    "productInfo": [{
                      "productId": "28657839764",
                      "productName": "Universal Stores",
                      "isPacketBased": false,
                      "productDescription": "Universal Lagos Mall",
                      "dateCreated": "2022-05-11T00:04:16.1418086+01:00",
                      "dateActivated": "2022-05-11T00:04:16.1408075+01:00",
                      "feeBearer": "Business",
                      "productState": "Development",
                      "settlementAccountId": 345,
                      "productPackages": null,
                      "webHookUrl": "",
                      "settlementAccount": {
                        "id": 345,
                        "accountStatus": "Active",
                        "bankCode": "000",
                        "accountNumber": "0123456789",
                      }
                    }],
                    "accountName": "Universal Nigeria Plc",
                    "bankName": "Future Nigeria bank",
                    "responseCode": 0,
                    "description": ""
                  },
                  responseDescription: [],
                  headerDescription: null,
                },
                {
                  title: 'Delete A Product',
                  url: '[Delete] -> Cardfree/Products/{id}',
                  description: ['Use this endpoint to delete a product if you have need for it. A product with at least one transaction can not be deleted.'],
                  sampleRequest: null,
                  requestDetails: null,
                  sampleResponse: {
                    "responseCode": 0,
                    "description": ""
                  },
                  responseDescription: [],
                  headerDescription: null,
                },
                {
                  title: 'Validate Account Name',
                  url: '[GET] -> Cardfree/bankinfo/nameInfo/{account}',
                  description: ['This endpoint is used to get the name on the specified account from the bank. There is a limit to API calls any merchant can make on this endpoint depending on the type of merchant.'],
                  sampleRequest: null,
                  requestDetails: null,
                  sampleResponse: {
                    "description": "",
                    "data": "name on account",
                    "referenceId": "Name enquiry reference code. Use this parameter to add the account in your collection accounts",
                  },
                  responseDescription: [],
                  headerDescription: null,
                },
                {
                  title: 'Create Collection Account',
                  url: '[POST] -> Cardfree/bankinfo/addaccount',
                  description: ['This endpoint is used to add a collection account to merchant profile'],
                  sampleRequest: {
                    "referenceId": "Reference Id received on name enquiry api call",
                  },
                  requestDetails: null,
                  sampleResponse: {
                    "description": "",
                    "responseCode": ""
                  },
                  responseDescription: [],
                  headerDescription: null,
                },
                {
                  title: 'Get Collection Accounts',
                  url: '',
                  description: [],
                  sampleRequest: null,
                  requestDetails: null,
                  sampleResponse: {
                    "settlementAccounts": [
                      {
                        "id": 890,
                        "accountNumber": "",
                        "accountName": "Chukwuma Atiku Olabisi",
                        "bankName": ""
                      },
                    ],
                    "code": 0,
                    "message": "Successful",
                    "data": null,
                    "successful": true

                  },
                  responseDescription: [],
                  headerDescription: null,
                },
              ]
            },
          ]
        },
      },
    }
  }
};

export default docs;
