const privacy = {
  title: "Privacy and Cookies Policy",
  effective: "April 2023",
  details: [
    {
      title: "",
      listType: '',
      item: ["We care about your privacy."],
      description: ''
    },
    {
      title: "",
      listType: '',
      item: ["This Online Privacy Policy explains what information we collect, how we collect, share, use, and protect your personal information when you visit or use this site and other services offered by Pethahiah Rehoboth International Limited.  As a Pethahiah Rehoboth International Limited, be assured that we will keep protecting your information as we have always done."],
      description: ''
    },
    {
      title: "",
      listType: '',
      item: ["Pethahiah Rehoboth International Limited’s customers have the opportunity to make choices about how their personal information may be shared, and we encourage you to make choices that enable us to provide you with quality products and services that help you meet your financial needs."],
      description: ''
    },
    {
      title: "Security of personal information",
      listType: '',
      item: ["Pethahiah Rehoboth International Limited will only collect your information in line with relevant regulations and laws."],
      description: ""
    },
    {
      title: "How do we collect information?",
      listType: 'list',
      listTitle: " We collect your personal information when... Data we collect:",
      item: [
        "you create profile on this site or our other services",
        "perform transactions such as make payments or transfer through this site, payment history and transactions records",
        "you seek advice about on how to use our service",
        "you seek information from our customer service provider, information concerning complaints and disputes",
        "you provide account information such as your personal details e.g. name,  gender, date and place of birth;  contact information such as address, email address, and mobile numbers, provide your employment information",
        "you provide information concerning your identity e.g. photo ID, passport information, National ID card and nationality",
        "you use your login credentials to perform transaction",
        "We conduct necessary investigations i.e., due diligence checks, and AML/CFT checks and obtain information that we need to support our regulatory obligations, e.g. information about transaction details, detection of any suspicious and unusual activities."
      ],
      description: "We may collect information from a range of sources and it may relate to any of our products or services we currently provide or may have provided in the past."
    },
    {
      title: "",
      listType: 'list',
      listTitle: "",
      item: [],
      description: "Note that we may collect information about your computer (or mobile device), including where available your IP address, operating system and browser type, for system administration or for our own commercial purposes. This is statistical data about our users' browsing actions and patterns, and does not identify any individual"
    },
    {
      title: "How we use your information",
      listType: 'list',
      listTitle: "",
      item: [
        "Pethahiah Rehoboth International Limited will only use your information when you have provided your consent or when Pethahiah Rehoboth International Limited is required by the law to do so.",
        "We use the information we collect to provide customers with excellent products and services, to manage our business and to offer an enriched and enhanced customer experience.",
        "We make appropriate use of your data to manage transactions, respond to your requests, and to provide you with more relevant products and services.",
        "We use your information to deliver our products and services, carry out your instructions, and provide Online payment or transfer services, mobile payment or transfer services and other online product and services.",
        "We use these information to detect and prevent financial crimes including fraud, financing for terrorism and money laundering, this is to ensure security and business continuity.",
        "We will use your information to meet our compliance obligations, to comply with laws and regulations and to share with regulators when absolutely necessary",
        "We may also use personal information we have about you such as your email address to deliver advertising to you directly or on our websites, provide updates on special deals and offers that might interest you (unless you tell us otherwise).",
        "We may send you general announcements or important news about your account profile, request your feedback and we believe you should be able to select what kinds of information you receive from us via email.",
        "We may need to record conversations you have with us including phone calls, face-to-face meetings, letters, emails and any other kinds of communication. These recordings may be used to check your instructions to us and improve on our product and service delivery."
      ],
      description: ''
    },
    {
      title: "Who we share your information with",
      listType: '',
      listTitle: "",
      item: ["We may also share your information where we have a public or legal duty to do so, when we need it to conclude regulatory reporting and when we have requested and received your permission to share it."],
      description: 'Pethahiah Rehoboth International Limited, in efforts to provide you with excellent products and services may need to outsource its product delivery or work with other related partners, this will be done in line with relevant regulations and law. We may share your information in order to provide you with products or services you’ve requested.'
    },
    {
      title: "Individual rights",
      listType: 'list',
      listTitle: "The right to be informed",
      item: ["To emphasize the need for transparency over the usage of personal data, we ensure fair processing of information typically through this privacy policy."],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      listTitle: "The rights to access",
      item: ["Individuals have the right to access information Pethahiah Rehoboth International Limited holds, access their personal data and other supplementary information and obtain information about how we process it"],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      listTitle: "The right to restrict processing",
      item: ["Individuals have a right to ‘block’ or withdraw their consent to our processing of your information, which you can do at any time. When processing is restricted, we are permitted to store the personal data, but not further process it."],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      listTitle: "The right to rectification",
      item: ["Individuals are entitled to have personal data rectified if it is inaccurate or incomplete. If this personal data in question has been disclosed to third parties, they must be informed of the rectification where possible. Pethahiah Rehoboth International Limited must also inform the individuals about the third parties to whom the data has been disclosed where appropriate"],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      listTitle: "The right to erasure",
      item: ["Individuals have the right to request the deletion or removal of personal data where there is no compelling legal or regulatory requirement for its continued processing. Pethahiah Rehoboth International Limited will make sure that this right is protected."],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      listTitle: "The right to data portability",
      item: ["We will ensure that personal data is moved, copied or transferred easily from one IT environment to another in a safe and secure way, without hindrance to usability."],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      listTitle: "The right to object",
      item: ["Individuals have the right to object to our processing of their information in some circumstances."],
      description: ''
    },
    {
      title: "Cookies",
      listType: 'list',
      listTitle: "",
      item: [
        "A cookie is a small file that is placed on your computer’s hard drive. Its functions include storing your login and session statuses, recording your user preferences and analyzing web traffic",
        "Cookies enable websites and applications to store your user preferences in order to enhance your overall website experience by better understanding your preferences, likes and dislikes. They also allow websites to identify when users are logged on their site and when they return to their site, test new content and analyse web traffic with data analytics.",
        "Apart from the date that you elect to disclose and share with us, we cannot access your computer or any other information about you with cookies.",
        "Although most browsers automatically accept cookies, you can amend your browser settings to disable cookies. This may however prevent you from fully experiencing the website as it was intended."
      ],
      description: ''
    },
    {
      title: "Individual Responsibility",
      listType: 'list',
      listTitle: "",
      item: [
        "If you would like further information on anything included in this Privacy Policy, contact us at 2nd Floor Rubby Block All seasons Plaza, Lateef Jakande Rd, Agidingbi, Ikeja, Lagos, support@pethahiah.com",
        "This Privacy Notice may be updated from time to time and the most recent version can be found on www.paythru.ng",
      ],
      description: 'Individuals are responsible for making sure the information provided to Pethahiah Rehoboth International Limited is accurate and should inform Pethahiah Rehoboth International Limited on any changes as it occurs, this will enable us to update your information with us.'
    }
  ]
};

export default privacy;