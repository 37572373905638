import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideNav from "./Components/SideNav";
import Header from "./Components/Header";
import BankSVG from '../../assets/svgs/dashboardsvg/bank.svg'
import SettingsSVG from '../../assets/svgs/dashboardsvg/settings.svg'
import ProductsSVG from '../../assets/svgs/dashboardsvg/products.svg'
import MandatesSVG from '../../assets/svgs/dashboardsvg/mandates.svg'
import DashboardSVG from '../../assets/svgs/dashboardsvg/dashboard.svg'
import './dashboard.scss';
import BodyWrapper from "./Components/BodyWrapper";

const productNavigation = {
  debit: [
    {
      name: 'Overview',
      route: '/enterprise/dashboard/debit/overview',
      icon: DashboardSVG
    },
    {
      name: 'Banks',
      route: '/enterprise/dashboard/debit/banks',
      icon: BankSVG
    },
    {
      name: 'Products',
      route: '/enterprise/dashboard/debit/products',
      icon: ProductsSVG
    },
    {
      name: 'Mandates',
      route: '/enterprise/dashboard/debit/mandates',
      icon: MandatesSVG
    },
    {
      name: 'Settings',
      route: '/enterprise/dashboard/debit/settings',
      icon: SettingsSVG
    }
  ],
  credit: [
    {
      name: 'Overview',
      route: '/enterprise/dashboard/credit/overview',
      icon: DashboardSVG
    },
    {
      name: 'Banks',
      route: '/enterprise/dashboard/credit/banks',
      icon: BankSVG
    },
    {
      name: 'Mandates',
      route: '/enterprise/dashboard/credit/mandates',
      icon: MandatesSVG
    },
    {
      name: 'Settings',
      route: '/enterprise/dashboard/credit/settings',
      icon: SettingsSVG
    }
  ],
  luc: []
};

const products = ['Debit', 'Credit', 'LUC'];

const Dashboards = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState('debit');

  useEffect(() => {
    navigate(`/enterprise/dashboard/${product.toLowerCase()}/overview`)
  }, [product]);

  return (
    <div className="dashboard">
      <SideNav
        products={products}
        navItems={productNavigation[product.toLowerCase()]}
        setProduct={setProduct}
        product={product}
      />
      <div className="outlet">
        <Header title="Overview" />
        <BodyWrapper />
        <a href="paythru.ng">paythru.ng &copy; 2023</a>
      </div>
    </div>
  )
};

export default Dashboards;
