import Business from '../../assets/business.png';
import User from '../../assets/user.png';
import Complete from '../../assets/complete.png';
import Upload from '../../assets/upload.png';
import './debit.css';
import { useEffect } from 'react';

function Debit() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth", block: "center", inline: "nearest"})
  }, []);
  return (
    <div className="App">
      <div className="debit-wrapper">
        <a href="http://apps.paythru.ng/Accounts/onboarding" target="_blank" rel="noreferrer" className="solution">
          <img src={Business} alt="Business" />
          <h2>Service Providers</h2>
          <p>Never chase a customer for payment anymore with PayThru™ Debit, a robust Direct Debit Management system for all your business needs.</p>
          <button>Get Started</button>
        </a>
        <a href="http://apps.paythru.ng/debit/mandate/enrollment" target="_blank" rel="noreferrer"  className="solution">
          <img src={User} alt="User" />
          <h2>Individual</h2>
          <p>
            We understand the importance of paying attention on things that matters most to you. Making payments for your bills and subscription services should not be part of things contending with your time. 
            With PayThru™ Debit, you can setup an automatic payments in favor of your service providers and forget about it.
          </p>
          <button>Enroll Now</button>
        </a>
        <a href="http://apps.paythru.ng/debit/mandate/new" target="_blank" rel="noreferrer"  className="solution">
          <img src={Complete} alt="Complete" />
          <h2>Complete Enrollment</h2>
          <p>If you have an enrollment code sent to you by your institution, use the button below to complete the enrollment.</p>
          <button>Complete Now</button>
        </a>
        <a href="http://apps.paythru.ng/debit/mandate/upload" target="_blank" rel="noreferrer" className="solution">
          <img src={Upload} alt="Card Free" />
          <h2>Upload Mandate</h2>
          <p>If you have signed copy of your mandate in pdf or image format, use the button below to upload it and finish your enrollment.</p>
          <button>Upload Document</button>
        </a> 
      </div>
    </div>
  );
}

export default Debit;
