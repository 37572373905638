import toast from "react-hot-toast";

export const scrollUp = () => {
  window.scroll({ top: 0, behavior: 'smooth' });
};

// export const navigatorShare = async (payload, dispatch) => {
//   if(navigator.share) {
//     try {
//       await navigator.share(payload);
//       toast.success('Share successfull');
//     } catch(err) {
//       toast.error('Error Sharing Item.');
//     }
//   } else {
//     dispatch(open({
//       open: true,
//       title: payload.title,
//       url: payload.url,
//       text: payload.text,
//       email: payload.email,
//     }));
//   }
// }

export const formateDateForReport = (date) => {
  date = new Date(date).toISOString().replaceAll('Z', ' ').replaceAll('T', ' ').split('.')[0];
  return date;
}

