import './pfasModal.scss';

const PFAsModal = ({pfas, closeModal}) => {
  return (
    <div className='pfas-modal'>
      <div className='container'>
        <p>Supported PFAs</p>
        <div className='pfas'>
          {
            pfas?.map((pfa) => (
              <div className='pfa-card' key={pfa.pfaCode}>
                <span>{pfa.pfaCode}</span>
                <b>{pfa.pfaName}</b>
              </div>
            ))
          }
        </div>
        <button onClick={() => closeModal(false)}>
          x
        </button>
      </div>
    </div>
  );
};

export default PFAsModal;
