class Utils {
    providers = {        
        /**
         * Get endpoint for the specified service. Returns live URL for the service if it's found in the live service list or return test URL
         * @param  {string} serviceName Name of the service to get Url for. Example Pension
         * @return {string}      Endpoint for service
         */
        endpointProviderFor(serviceName) {
            const  _liveServices = ["Pension","LUC"];
                if(!serviceName){
                    throw new Error("Service name required");
                }
let service = _liveServices.find(item => item.toLowerCase() === serviceName.toLowerCase());

                if (service !== undefined) {
                    return process.env.REACT_APP_LIVE_API_BASE_URL;
                }
                return process.env.REACT_APP_TEST_API_BASE_URL;
            }
    }
}


export default new  Utils();

export const getSignature  = (str) => {
    return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
        return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    });
}

export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
