import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';

const NavItems = ({type}) => {
  return (
    <>
      <NavHashLink to="/#hero" smooth><li>Home</li></NavHashLink>
      <NavHashLink to="/#about-us" smooth><li>About Us</li></NavHashLink>
      <Link to="documentations"><li>Documentations</li></Link>
      <NavHashLink to="/#solutions" smooth className="dropdown">
        <li>Payment Solutions</li>
        {
          type === 'main' && (
            <div className="dropdown-content">
              <HashLink to="payments/pensions#header" smooth>
                Pensions
              </HashLink>
              <HashLink to="luc" smooth>
                LUC
              </HashLink>
            </div>
          )
        }
      </NavHashLink>
    
      {/* <NavHashLink to="/documentions" smooth><li>Documentations</li></NavHashLink> */}
      {/* <NavHashLink to="/#developers" smooth><li>Developers</li></NavHashLink> */}
    </>
  );
};

export default NavItems;
