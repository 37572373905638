import toast from 'react-hot-toast';
const renderToast = (text, toastType, duration, position = "top-right", overrideStyle) => {
  toast((t) => (
    <span className='toast-close-btn' style={{fontSize: '18px', fontWeight: '900'}}>
      <b style={{marginRight: '10px'}}>{toastType === 'success' ? '✅' : '❌'}</b>
      {text}
      <button onClick={() => toast.dismiss(t.id)}>
        x
      </button>
    </span>
  ), {
    duration,
    position,
    style: overrideStyle
  });
}

export default renderToast;
