import AboutUsImg from '../../assets/aboutusimg.jpeg';

import './aboutus.css';

function AboutUs() {
  return (
    <section className="about-us" id='about-us'>
      <img src={AboutUsImg} alt="" />
      <div className="content">
        <h1>About Us</h1>
        <p>
          PayThru™ is a service provided by Pethahiah Rehoboth
          International ltd, a CBN licensed payment solution 
          service provider and IT firm which is based in Lagos. 
          We are also a value added service company which 
          provides telecommunications services, digital signage
          and promotional services. Our ultimate goal is to 
          ensure that values are added to subscribers’ life styles
          via our Value Added Services (VAS).
        </p>
        <a href="http://apps.paythru.ng/Accounts/onboarding" target="_blank" rel="noreferrer" className=""><button>Get Started</button></a>
      </div>
    </section>
  );
}

export default AboutUs;
