import FormWrapper from '../../components/FormWrapper';
import PenseionImage from '../../assets/pensionForm.jpg';
import './pensionContribution.scss';
import MetaTags from 'react-meta-tags';
import MicroPensionForm from '../../components/Forms/MicroPensions';

const MicroPension = () => {
  return (
    <div className='pensions'>
      <MetaTags>
        <title>MicroPension Remittance - PayThru&trade;</title>
        <meta id="meta-description" name="description" content="Single and bulk pension payment. Pay easily in few steps. Powered by EPCOSS" />
        <meta id="og-title" property="og:title" content="RSA Remittance - PayThru&trade;" />
      </MetaTags>
      <div id="header"/>
      <FormWrapper
        title="Micro Pension Payment"
        overrideLeftStyle={{backgroundImage: `url(${PenseionImage})`}}
      >
        <MicroPensionForm />
      </FormWrapper>
    </div>
  )
};

export default MicroPension;
