import { useEffect } from "react";
import ContentBuilder from "../../components/ContentBuilder";
import privacy from "../../data/privacy";
import { scrollUp } from "../../utils/common";


const Privacy = () => {
  useEffect(() => {
    scrollUp();
  }, []);
  return (
    <div style={{marginTop: '100px'}}>
      <ContentBuilder data={privacy} />
    </div>
  );
};

export default Privacy;
