import ReactDOM from 'react-dom';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../Button';
import Input from '../Input';

import { useEffect } from 'react';

export const ExcelDisplay = (props) => {
  const [selectedEntry, setEntry] = useState(null);
  const [toDelete, setToDelete] = useState(null);
  const [errors, setErrors] = useState(null);

  const handleEditRowOpen = (item) => {
    setEntry(item);
  }

  const closeForm = () => {
    setEntry(null);
  }

  const handleAddRow = (data) => {
    props.addNewRow(data, closeForm);
  }

  const handleEditRow = (data) => {
    props.editExcelRow(data, selectedEntry, closeForm);
  }

  const closeConfirm = () => {
    setToDelete(null);
  }

  const handleConfirmDelete = (item) => {
    setToDelete(item);
  }

  const handleRemoveRow = () => {
    props.removeExcelRow(toDelete, closeConfirm);
  }

  const handleDone = () => {
    const hasduplicates = Object.values(props.rsapinMap).filter((item) => item > 1);
    if (hasduplicates.length > 0) {
      toast.error('Excel File Contains duplicates records. Please correct the rows in red', { duration: 10000});
      return;
    }
    let errors = [];
    Object.values(props.excelError.current).forEach(element => {
      errors.push(element[0])
    });
    errors = Array.from(new Set(errors));
    if (errors.length > 0) {
      toast.error(() => (
        <div>
          <b>Excel File Contains some errors! </b>
          <div>
            {
              errors.map((item) => <li key={item}>{item}</li>)
            }
          </div>
        </div>
      ), { duration: 10000});
      return;
    }
    props.handlePreviewDone();
  }

  return ReactDOM.createPortal(
    <div className='excel-display'>
      {
        toDelete ? (
          <ConfirmDelete
            data={toDelete}
            removeData={handleRemoveRow}
            closeModal={setToDelete}
          /> 
        ) :
        selectedEntry ? (
          <AddEntryForm
            setEntry={setEntry}
            prevData={selectedEntry}
            addEntry={handleAddRow}
            editData={handleEditRow}
            rsapinMap={props.rsapinMap}
          />
        ) : (
          <>
            <div className='content'>
              <p>Preview Excel Data.</p>
              <div>
                {errors && errors.map((error) => <div key={error}>{error}</div>)}
              </div>
              <div className='display'>
                <ExcelHeader data={props.filteredDataArrObj[0]} />
                <ExcelRow
                  data={props.filteredDataArrObj.slice(1)}
                  handleEditRow={handleEditRowOpen}
                  setErrors={setErrors}
                  setToDelete={handleConfirmDelete}
                  rsapinMap={props.rsapinMap}
                  setExcelError={props.setExcelError}
                  excelError={props.excelError}
                />
              </div>
            </div>
            <div className="btn-group">
              <Button onClick={() => props.handleCancelExcelPreview()} label="Cancel" className="input" />
              <Button onClick={() => setEntry({})} label="New Row" type="button" className="submitBtn" />
              <Button onClick={() => handleDone()} label="Done" type="button" className="submitBtn" />
            </div>
          </>
        )
      }
    </div>,
    document.getElementById('modal-root')
  );
}

// EmployeeName
// NormalContributionEmployee
// VoluntaryContributionEmployee
// NormalContributionEmployer
// VoluntaryContributionEmployer
// PfaCode
// Rsapin
// StaffId

export const ExcelHeader = ({data}) => {
  if (data) {
    return (
      <div className='row header'>
        <div className='cell sn'>S/N</div>
        <div className='cell'>{data.employeeName}</div>
        <div className='cell'>{data.normalContributionEmployee}</div>
        <div className='cell'>{data.voluntaryContributionEmployee}</div>
        <div className='cell'>{data.normalContributionEmployer}</div>
        <div className='cell'>{data.voluntaryContributionEmployer}</div>
        <div className='cell'>{data.pfaCode}</div>
        <div className='cell'>{data.rsapin}</div>
        <div className='cell'>{data.staffId}</div>
        <div className='cell action'>Actions</div>
      </div>
    );
  }
  return null;
};

export const ExcelRow = ({data, handleEditRow, setToDelete, rsapinMap, setExcelError, excelError}) => {
  const handleExcelError = (error, __id) => {
    const newErrors = excelError.current;
    if (!newErrors[__id]) {
      newErrors[__id] = [error];
    } else {
      const newArr = newErrors[__id];
      newArr.push(error);
      newErrors[__id] = Array.from(new Set(newArr));
    }
    setExcelError(newErrors);
  }

  const duplicateRecord = (rsapin) => {
    const isValid = rsapinMap[rsapin] > 1;
    return isValid;
  };

  const verifyNCEmployer = (amt, id) => {
    const isValid = parseInt(amt) > 0;
    if (!isValid) {
      handleExcelError('Invalid Normal Employer Contribution found!', id)
    }
    return isValid;
  };

  const verifyNCEmployee = (amt, id) => {
    const isValid = parseInt(amt) > 0;
    if (!isValid) {
      handleExcelError('Invalid Normal Employee Contribution found!', id)
    }
    return isValid;
  };

  const verifyName = (name, id) => {
    const isValid = name && name.trim().length > 0;
    if (!isValid) {
      handleExcelError('Invalid name found!', id)
    }
    return isValid;
  }

  const verifyrsapin = (pin, id) => {
    const isValid = pin && pin.trim().length === 15;
    if (!isValid) {
      handleExcelError('Invalid RSA Pin found!', id)
    }
    return isValid; 
  }

  const errorStyle = {
    background: '#cd5c5c9e',
    border: 'none'
  }

  if (!data) return null;
  return (
    <>
     {
      data && data.map((item, index) => (
        <div onClick={() => handleEditRow(item)} className='row' key={index.toString()} style={duplicateRecord(item.rsapin) ? errorStyle : {}}>
          <div className='cell sn'>{index + 1}</div>
          <div className='cell' style={!verifyName(item.employeeName, item.__id) ? errorStyle : {}}>{item.employeeName}</div>
          <div className='cell' style={!verifyNCEmployee(item.normalContributionEmployee, item.__id) ? errorStyle : {}}>{item.normalContributionEmployee}</div>
          <div className='cell'>{item.voluntaryContributionEmployee}</div>
          <div className='cell' style={!verifyNCEmployer(item.normalContributionEmployer, item.__id) ? errorStyle : {}}>{item.normalContributionEmployer}</div>
          <div className='cell'>{item.voluntaryContributionEmployer}</div>
          <div className='cell'>{item.pfaCode}</div>
          <div className='cell' style={!verifyrsapin(item.rsapin, item.__id) ? errorStyle : {}}>{item.rsapin}</div>
          <div className='cell'>{item.staffId}</div>
          <div className='cell action'>
            <button onClick={(event) => {
              event.stopPropagation();
              setToDelete(item)
            }}>Delete</button>
            <button onClick={(event) => {
              event.stopPropagation();
              handleEditRow(item)
            }}>Edit</button>
          </div>
        </div>
      ))
     }
    </>
  )
};

export const AddEntryForm = ({setEntry, addEntry, prevData, index, editData, rsapinMap}) => {
  const [data, setData] = useState({
    pfaCode: '',
    rsapin: '',
    staffId: '',
    employeeName: '',
    normalContributionEmployee: '',
    voluntaryContributionEmployee: '',
    normalContributionEmployer: '',
    voluntaryContributionEmployer: '',
  });

  useEffect(() => {
    if (prevData) {
      setData({
        pfaCode: prevData.pfaCode,
        rsapin: prevData.rsapin,
        staffId: prevData.staffId,
        employeeName: prevData.employeeName,
        normalContributionEmployee: prevData.normalContributionEmployee,
        voluntaryContributionEmployee: prevData.voluntaryContributionEmployee,
        normalContributionEmployer: prevData.normalContributionEmployer,
        voluntaryContributionEmployer: prevData.voluntaryContributionEmployer,
        __id: prevData.__id
      });
    }
  }, [prevData]);

  const handleAddEntry = (event) => {
    event.preventDefault();
    if ((rsapinMap[data.rsapin] >= 1 && !prevData.rsapin) || (rsapinMap[data.rsapin] > 1 && prevData.rsapin)) {
      toast.error(`User with ${data.rsapin} already on the list`, {
        duration: 15000
      });
      return
    }
    prevData.__id ? editData(data) : addEntry({...data, __id: Date.now()});
  }

  const handleDataChange = useCallback((field, value) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }, []);

  return (
    <form className="form-wrapper" onSubmit={handleAddEntry}>
      <h3>{`${index !== null ? 'Edit' : 'Add'}`} Beneficiaries</h3>
        <fieldset>
          <div className="form-group">
            <Input
              label="Employee Name"
              className="input"
              name="employeeName"
              required
              value={data.employeeName}
              onChange={handleDataChange}
            />
            <Input
              label="PFA Code"
              className="input"
              name="pfaCode"
              required
              value={data.pfaCode}
              onChange={handleDataChange}
            />
          </div>
          <div className="form-group">
            <Input
              label="Employee NC"
              className="input"
              name="normalContributionEmployee"
              required
              value={data.normalContributionEmployee}
              onChange={handleDataChange}
            />
            <Input
              label="Employee VC"
              className="input"
              name="voluntaryContributionEmployee"
              required
              value={data.voluntaryContributionEmployee}
              onChange={handleDataChange}
            />
          </div>
          <div className="form-group">
            <Input
              label="Employer NC"
              className="input"
              name="normalContributionEmployer"
              required
              value={data.normalContributionEmployer}
              onChange={handleDataChange}
            />
            <Input
              label="Employer VC"
              className="input"
              name="voluntaryContributionEmployer"
              required
              value={data.voluntaryContributionEmployer}
              onChange={handleDataChange}
            />
          </div>
          <div className="form-group">
            <Input
              label="RSA PIN"
              className="input"
              name="rsapin"
              pattern="([0-9a-zA-z]){15}"
              placeholder='PEN456789012345'
              required
              value={data.rsapin}
              onChange={handleDataChange}
            />
            <Input
              label="Staff Id"
              className="input"
              name="staffId"
              required
              value={data.staffId}
              onChange={handleDataChange}
            />
          </div>
          <div className='note'>
            <b>NC: Normal Contribution</b>
            <b>VC: Voluntary Contribution</b>
          </div>
        </fieldset>
      <div className="form-group btn-wrapper small-top">
        <Button onClick={() => setEntry(null)} label="Cancel" className="input" />
        <Button btnActionType="submit" label="Submit" className="input submitBtn" />
      </div>
    </form>
  );
};

export const ConfirmDelete = ({data, removeData, closeModal}) => {
  return (
    <div className='confirm-delete'>
      <div><h3>Are you sure you want to remove this record?</h3></div>
      <div className='item-wrapper'>
        <div className='item'>
          <span>EmployeeName</span>
          <b>{data.employeeName}</b>
        </div>
        <div className='item'>
          <span>StaffId</span>
          <b>{data.staffId}</b>
        </div>
      </div>
      <div className='item-wrapper'>
        <div className='item'>
          <span>NormalContributionEmployee</span>
          <b>{data.normalContributionEmployee}</b>
        </div>
        <div className='item'>
          <span>VoluntaryContributionEmployee</span>
          <b>{data.voluntaryContributionEmployee}</b>
        </div>
      </div>
      <div className='item-wrapper'>
        <div className='item'>
          <span>NormalContributionEmployer</span>
          <b>{data.normalContributionEmployer}</b>
        </div>
        <div className='item'>
          <span>VoluntaryContributionEmployer</span>
          <b>{data.voluntaryContributionEmployer}</b>
        </div>
      </div>
      <div className='item-wrapper'>
        <div className='item'>
          <span>PfaCode</span>
          <b>{data.pfaCode}</b>
        </div>
        <div className='item'>
          <span>Rsapin</span>
          <b>{data.rsapin}</b>
        </div>
      </div>
      <div className='btn-wrapper'>
        <button onClick={() => closeModal(null)}>No</button>
        <button onClick={removeData}>Yes</button>
      </div>
    </div>
  )
}