import { Outlet } from "react-router-dom";
import './mandate.scss';

const Mandates = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Mandates;
