import Button from "../../../Button";
import FormWrapper from "../../../FormWrapper";
import Input from "../../../Input";
import './downloadQR.scss';

const DownloadQR = () => {
  return (
    <FormWrapper title="Download QR Code">
      <div className="form">
        <div className="form-wrapper">
          <div className="form-group">
            <Input
              label="Sub Merchant Number"
              className="select"
            />
          </div>
        </div>
        <div className="form-group">
          <Button label="Submit" className="input submitBtn" />
        </div>
      </div>
    </FormWrapper>
  );
};

export default DownloadQR;
