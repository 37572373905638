import Solutions from '../../components/Solutions';
import Hero from '../../components/Hero';
import AboutUs from '../../components/AboutUs';
import ContactUs from '../../components/ContactUs';

function Home() {
  return (
    <div className="App">
      <Hero /> 
      <Solutions />
      <AboutUs />
      <ContactUs />
    </div>
  );
}

export default Home;
