import {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Business from '../../assets/business.png';
import User from '../../assets/user.png';
import Download from '../../assets/download.png';
import './nqr.css';

function NQR() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth", block: "center", inline: "nearest"})
  }, []);
  return (
    <div className="App">

      <div className="nqr-wrapper">
        <Link to="/nqr/register" className="solution">
          <img src={Business} alt="Business" />
          <h2>Register</h2>
          <p>Experience faster transactions with instant credit on your account at the lowest transaction fee in the industry. Register your business and get QR Code today.</p>
          <button>Get Started</button>
        </Link>
        <Link to="/nqr/submerchant" className="solution">
          <img src={User} alt="User" />
          <h2>Creat Sub Merchant</h2>
          <p>NQR gives you the capability to track your transactions according to each of your sales persons. Each person can have a Qr Code tied to their name and the payment goes to same business account. Each of your sales person is a sub merchant.</p>
          <button>Get Started</button>
        </Link>
        <Link to="/nqr/getqrcode" className="solution">
          <img src={Download} alt="Download " />
          <h2>Download Code</h2>
          <p>If you have already registered for NQR on this platform and want to download your QR Code, you can do that easily here.</p>
          <button>Download</button>
        </Link>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default NQR;
