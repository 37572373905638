import { useState, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Button from "../../Button";
import FormWrapper from "../../FormWrapper";
import Input from "../../Input";
import { CustomMultiSelect } from "../../MultiSelect";
import OnboardImage from "../../../assets/onboard.jpg";
import './onboard.scss';
import { useEffect } from "react";

const Onboard = () => {
  const url = `${process.env.REACT_APP_TEST_API_BASE_URL}api/v1/core/collection/`; //`${Utils.providers.endpointProviderFor("Pension")}api/v1/core/collection/`;
  const apiKey = process.env.REACT_APP_API_KEY;
  const [products, setProducts] = useState([
    { label: "CardFree", value: "CardFree" },
    { label: "Debit", value: "Debit" },
    { label: "Pension", value: "Pension"},
  ]);
  const [data, setData] = useState({
    emailAddress: '',
    otp: '',
    selectedProducts: [],
    businessName: '',
    phone: '',
    businessAddress: '',
    businessRegistrationNumber: '',
    employeeSize: '',
    description: '',
    referrerCode: '',
    webSiteAddress: ''
  });
  const [step, setStep] = useState(0);
  const isFirst = step === 0;
  const isSecond = step === 1;
  const isThird = step === 2;
  const isForth = step === 3;
  const renderStep = () => {
    return (
      <div className="stepper">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isThird ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isForth ? 'active' : ''}`} />
      </div>
    )
  };

  useEffect(() => {
    getProducts();
  }, []);

  const handleDataChange = useCallback((field, value) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }, []);


  const validateEmail = (event) => {
    event.preventDefault();
    const postEmailAddress = axios.post(`${url}onboarding/generateToken`,
      {
        emailAddress: data.emailAddress
      },
      {
      headers: {
        'Authorization': `Paythru ${apiKey}`
      }
    });
    toast.promise(
      postEmailAddress,
       {
         loading: 'Validating email address...',
         success: (resp) => {
          setData((prev) => ({
            ...prev,
            employerName: resp?.data?.employerInfo?.name
          }))
          setStep(1);
          return 'Token sent to your email!'
        },
        error: (error) => {
          setStep(1);
          return 'Error validating email!'
        },
       },
       {
        style: {
          minWidth: '250px',
          fontWeight: 'bold',
        },
      }
     );
  }

  const validateToken = (event) => {
    event.preventDefault();
    const postToken = axios.post(`${url}onboarding/validateToken`,
      {
        emailAddress: data.emailAddress,
        otp: data.otp,
        selectedProducts: data.selectedProducts,
        businessName: data.businessAddress,
        phone: data.phone,
        businessAddress: data.businessAddress,
        businessRegistrationNumber: data.businessRegistrationNumber,
        employeeSize: data.employeeSize,
        description: data.description,
        referrerCode: data.referrerCode,
        webSiteAddress: data.webSiteAddress,
      },
      {
      headers: {
        'Authorization': `Paythru ${apiKey}`
      }
    });
    toast.promise(
      postToken,
       {
         loading: 'Validating OTP address...',
         success: (resp) => {
          console.log(resp);
          setData((prev) => ({
            ...prev,
            employerName: resp?.data?.employerInfo?.name
          }))
          setStep(2);
          return 'Token validated successfully!'
        },
        error: (error) => {
          console.log(error)
          setStep(2);
          return 'Error validating token!'
        },
       },
       {
        style: {
          minWidth: '250px',
          fontWeight: 'bold',
        },
      }
     );
  }

  const getProducts = () => {
    axios.get(`${url}onboarding/products`, {
      headers: {
        'Authorization': `Paythru ${apiKey}`
      }
    })
    .then((resp) => {
      setProducts(resp?.data);
    })
    .catch(() => null);
  };

  const submitProducts = () => {
    setStep(step + 1)
  }

  const handleRegister = (event) => {
    event.preventDefault();
    const registerMerchant = axios.post(`${url}onboarding/merchant`,
      {
        emailAddress: data.emailAddress,
        selectedProducts: [],
        businessName: '',
        phone: '',
        businessAddress: '',
        businessRegistrationNumber: '',
        employeeSize: '',
        description: '',
        referrerCode: '',
        webSiteAddress: ''
      },
      {
      headers: {
        'Authorization': `Paythru ${apiKey}`
      }
    });
    toast.promise(
      registerMerchant,
       {
         loading: 'Enrolling you...',
         success: (resp) => {
          return 'Your enrollment was successful, Login in to continue!'
        },
        error: (error) => {
          return 'Error enrolling you!'
        },
       },
       {
        style: {
          minWidth: '250px',
          fontWeight: 'bold',
        },
      }
     );
  }

  return (
    <>
      <div id="header"/>
      <FormWrapper
        title="Get Onboard"
        subTitle={renderStep()}
        overrideLeftStyle={{backgroundImage: `url(${OnboardImage})`}}
      >
        <div className="form">
          {
            step === 0 && (
              <form className="form-wrapper" onSubmit={validateEmail}>
                <p>Business Information</p>
                <div className="form-group">
                  <Input
                    label="Business Email"
                    className="select"
                    name="emailAddress"
                    type="email"
                    required
                    value={data.emailAddress}
                    onChange={handleDataChange}
                  />
                </div>
                <div className="form-group btn-wrapper">
                  <Button label="Submit" className="input submitBtn" btnActionType="submit" />
                </div>
              </form>
            )
          }
          {
            step === 1 && (
              <form className="form-wrapper" onSubmit={validateToken}>
                <p>Enter Code sent to the Supplied Email</p>
                <div className="form-group">
                  <Input
                    label="OTP" 
                    className="select"
                    name="otp"
                    required
                    value={data.otp}
                    onChange={handleDataChange}
                  />
                </div>
                <div className="form-group btn-wrapper">
                  <Button label="Validate OTP" className="input submitBtn" btnActionType="submit" />
                </div>
              </form>
            )
          }
          {
             step === 3 && (
              <form className="form-wrapper" onSubmit={handleRegister}>
                <p>Business Information</p>
                <div className="form-group">
                  <Input
                    label="Business Name"
                    className="input"
                    name="businessName"
                    required
                    value={data.businessName}
                    onChange={handleDataChange}
                  />
                  <Input
                    label="Website" 
                    className="input" 
                    name="webSiteAddress"
                    value={data.webSiteAddress}
                    onChange={handleDataChange}
                  />
                </div>
                <div className="form-group">
                  <Input 
                    label="Business Phone" 
                    className="input"
                    name="phone"
                    required
                    type="number"
                    value={data.phone}
                    onChange={handleDataChange}
                  />
                  <Input
                    label="Business Address" 
                    className="input"
                    name="businessAddress"
                    required
                    value={data.businessAddress}
                    onChange={handleDataChange}
                  />
                </div>
                <div className="form-group">
                  <Input
                    label="Referrer Code"
                    className="input"
                    name="referrerCode"
                    value={data.referrerCode}
                    onChange={handleDataChange}
                  />
                  <Input
                    label="RC Number"
                    className="input"
                    name="businessRegistrationNumber"
                    value={data.businessRegistrationNumber}
                    onChange={handleDataChange}
                  />
                </div>
                <div className="form-group">
                  <Input
                    label="Description" 
                    className="input"
                    name="description"
                    required
                    value={data.description}
                    onChange={handleDataChange}
                  />
                  <Input
                    label="Employee Size" 
                    className="input"
                    name="employeeSize"
                    value={data.employeeSize}
                    onChange={handleDataChange}
                  />
                </div>
                <div className="form-group btn-wrapper">
                  <Button onClick={() => setStep(step - 1)} label={step === 0 ? "Next" : "Previous"} className="input" />
                  <Button label="Submit" className="input submitBtn" btnActionType="submit" />
                </div>
              </form>
            )
          }
          {
            step === 2 && (
              <div className="form-wrapper">
                <p>Select your Product(s) of Choice.</p>
                <div className="form-group">
                  <CustomMultiSelect
                    label="Products"
                    value={data.selectedProducts}
                    data={products}
                    onChange={handleDataChange}
                    name="selectedProducts"
                    className="select"
                  />
                </div>
                <div className="form-group btn-wrapper">
                  <Button onClick={submitProducts} label="Add Products Later" className="input" />
                  <Button onClick={submitProducts} label="Continue" className="input submitBtn" btnActionType="button" />
                </div>
              </div>
            )
          }
        </div>
      </FormWrapper>
    </>
  );
};

export default Onboard;
