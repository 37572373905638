import PensionForm from '../../components/Forms/Pensions';
import FormWrapper from '../../components/FormWrapper';
import PenseionImage from '../../assets/pensionForm.jpg';
import './pensionContribution.scss';
import MetaTags from 'react-meta-tags';

const PensionContribution = () => {
  return (
    <div className='pensions'>
      <MetaTags>
        <title>RSA Remittance - PayThru&trade;</title>
        <meta id="meta-description" name="description" content="Single and bulk pension payment. Pay easily in few steps. Powered by EPCOSS" />
        <meta id="og-title" property="og:title" content="RSA Remittance - PayThru&trade;" />
      </MetaTags>
      <div id="header"/>
      <FormWrapper
        title="Pension Payment"
        overrideLeftStyle={{backgroundImage: `url(${PenseionImage})`}}
      >
        <PensionForm />
      </FormWrapper>
    </div>
  )
};

export default PensionContribution;
