import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import './input.scss';


const Input = React.forwardRef((props, ref) => {
  return (
    <div className={`input-wrapper ${props.className}`}>
      <div className='label-wrapper'>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        <Link to={`/${props.ctaRoute}`}><span>{props.ctaText}</span></Link>
      </div>
      <input
        type={props.obscureText ? "password" :( props.type || "text")}
        readOnly={props.readonly}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        required={props.required}
        pattern={props.pattern ? props.pattern : '.*?'}
        onChange={({target}) => {
          props.onChange && props.onChange(props.name, target.value)
        }}
        onBlur={() => {
          props.onBlur && props.onBlur()
        }}
        ref={ref}
      />
      {props.hasError && <p>{props.errorText}</p>}
    </div>
  );
});

export default Input;


export const FileInput = React.forwardRef((props, ref) => {
  const [fileName, setFileName] = useState('');
  return (
    <div className={`input-wrapper ${props.className}`}>
      <div className='label-wrapper'>
        {props.label && <label htmlFor={props.name} className="file-label">
          {props.label}
          <div className='file-input'>
            <p>Upload File</p>
            <span>{fileName}</span>
          </div>
          <input
            name={props.name}
            id={props.name}
            type="file"
            required={props.required}
            onChange={({target}) => {
              const file = target.files[0];
              setFileName(target.files[0].name);
              props.onChange && props.onChange(props.name, file)
            }}
            onBlur={() => {
              props.onBlur && props.onBlur()
            }}
            style={{
              backgroundColor: 'transparent',
              display: 'none'
            }}
            accept={props.accept}
            ref={ref}
          />
        </label>}
      </div>
      {props.hasError && <p>{props.errorText}</p>}
    </div>
  );
});

export const SelectComponent = ({
  data,
  value,
  ...props
}) => {
  return (
    <div className={`input-wrapper ${props.className}`}>
      <div className='label-wrapper'>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        <Link to={`/${props.ctaRoute}`}><span>{props.ctaText}</span></Link>
      </div>
      <select
        name={props.name}
        value={value}
        required={props.required}
        onChange={({target}) => {
          props.onChange && props.onChange(props.name, target.value)
        }}
        onBlur={() => {
          props.onBlur && props.onBlur()
        }}
      >
        <option disabled></option>
        {
          data && data.map((item) => <option value={item} key={item}>{item}</option>)
        }
      </select>
      {props.hasError && <p>{props.errorText}</p>}
    </div>
  )
}

export const ReactSelect = ({
  data,
  value,
  renderOptions,
  ...props
}) => {
  return (
    <div className={`input-wrapper ${props.className}`}>
      <div className='label-wrapper'>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        <Link to={`/${props.ctaRoute}`}><span>{props.ctaText}</span></Link>
      </div>
      <Select
        className="react-select-container"
        options={renderOptions}
        name="bankName"
        value={value ? {value, label: value} : null}
        placeholder={props.placeholder}
        onChange={(item) => {
          if (item) {
            props.onChange && props.onChange(props.name, item)
          }
        }}
      />
      {props.hasError && <p>{props.errorText}</p>}
    </div>
  )
}
