import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import Button from '../Button';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';
import Logo from '../../assets/paythruLogo.png';
import NavItems from '../NavItems';
import Facebook from '../../assets/facebook.png';
import Twitter from '../../assets/Twitter.png';
import Instagram from '../../assets/instagram.png';
import Youtube from '../../assets/youtube.png';
import './newFooter.scss';

const socials = [
  {
    icon: Facebook,
    link: 'https://facebook.com/azatmepay'
  },
  {
    icon: Youtube,
    link: 'https://youtube.com/@azatmepay'
  },
  {
    icon: Instagram,
    link: 'https://instagram.com/azatmepay'
  },
  {
    icon: Twitter,
    link: 'https://twitter.com/azatmepay'
  }
]

function Footer() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const saveSub = (event) => {
    event.preventDefault();
    handleExcelRecord(setLoading, {
      name: 'Subscription',
      phone: 'Subscription',
      email,
      message: 'Subscription'
    },
    () => {
      setEmail('');
    },
    'Thanking you for subscribing to our mailing list.',
    'Error saving your Intent!',
    'Subscription'
    )
  };
  const {pathname} = useLocation();
  if (pathname && pathname.search('enterprise/dashboard') >= 0) {
    return null;
  }

  return (
    <div className="footer">
      <div className="top">
        <div className="home section">
          <h5>Home</h5>
          <ul>
            <NavItems />
          </ul>
        </div>
        <div className="resources section">
          <h5>Resources</h5>
          <ul>
            <NavLink to="/faqs"><li>FAQs</li></NavLink>
            <NavLink to="/privacy"><li>Privacy Policy</li></NavLink>
            <NavLink to="/privacy"><li>User Agreement</li></NavLink>
            <NavLink to="/privacy"><li>Terms & Conditions</li></NavLink>
          </ul>
        </div>
        <div className="links  section">
          <h5>Quick Links</h5>
          <ul>
            <a href="https://apps.paythru.ng/Identity/login"><li>Login</li></a>
            <a href="https://apps.paythru.ng/Accounts/onboarding"><li>Onboard</li></a>
            <NavLink to="documentations"><li>Documentations</li></NavLink>
            <a href="https://apps.paythru.ng/Accounts/onboarding"><li>Get Started</li></a>
          </ul>
        </div>
        <div className="subscribe section">
          <h5>Subscribe to Our Newsletter</h5>
          <p>Subscribe to be the first one to know about updates.<br /> Enter your email</p>
          <form className='footer-form' onSubmit={saveSub}>
            <input
              type="email"
              value={email}
              onChange={({target}) => {
                setEmail(target.value);
              }}
              placeholder='Email Address' 
              required
            />
            <Button 
              type='flat'
              btnActionType='submit'
              label={loading ? 'Loading' : 'Subscribe'}
              overrideStyle={{
                color: '#000',
                fontWeight: '900',
                fontFamily: 'Philosopher'
              }}
            />
          </form>
        </div>
      </div>
      <div className="bottom">
        <div className='brand'>
          <img className='logo' src={Logo} alt="refundme" />
          <p>Pethahiah Rehoboth &copy; {new Date().getFullYear()}</p>
        </div>
        <div className="s-media">
          {
            socials.map(({link, icon}) => (
              <a
                key={link} 
                href={link} 
                target="_blank" 
                rel="noreferrer"
                // onClick={() => trackAction(link.split('//')[1], 'Social Media Click')}
              >
                <img src={icon} alt="" />
              </a>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Footer;

export const handleExcelRecord = (setLoading, data, setData, successMsg, errorMsg, type) => {
  // setLoading(true);
  // toastWrapper(
  //   axios.post('https://sheet.best/api/sheets/4cf25bba-8e69-4282-ba8f-bffffc490ce3', data), 
  //   `Saving ${type}...`, 
  //   () => {
  //     setLoading(false);
  //     setData();
  //     return successMsg
  //   }, 
  //   errorMsg,
  //   (error) => {
  //     setLoading(false);
  //     return errorMsg
  //   }
  // );
}

