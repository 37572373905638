import { useMemo, useState } from "react";
import Table from "../../../../../../components/TableBuilder";
import { makeData } from "../../../../../../components/TableBuilder/makeData";
import { fuzzySort } from "../../../../../../utils/table";


const Schedules = () => {
  const [data] = useState(() => makeData(50))

  const columns = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        header: () => <span>Product Name</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Amount</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'age',
        header: () => 'Duration',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'Reference',
        header: () => 'Remarks',
        footer: props => props.column.id,
      },
      {
        accessorFn: row => `${row.firstName} ${row.lastName}`,
        id: 'fullName',
        header: 'Remarks',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Account Number</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Phone Number</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Payer Name</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Payer's Address</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>File Name</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Description</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'progress',
        header: 'Institution',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'progress',
        header: 'Email',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'progress',
        header: 'Payment Frequency',
        footer: props => props.column.id,
      },
      // {
      //   accessorKey: 'progress',
      //   header: 'Options',
      //   footer: props => props.column.id,
      //   cell: (props) => {
      //     console.log(props)
      //     return (
      //       <div className="table-options">
      //         <button>...</button>
      //         <div className="dropdown">
      //           <button>Invoices</button>
      //           <button>Schedules</button>
      //           <button>Settlements</button>
      //           <button>Transactions</button>
      //         </div>
      //       </div>
      //     )
      //   }
      // },
    ],
    []
  )
  return (
    <div className="mandate-list">
      <h3>Schedules</h3>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default Schedules;
