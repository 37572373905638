import MetaTags from 'react-meta-tags';
import FormWrapper from '../../components/FormWrapper';
import LUCImage from '../../assets/luc.jpg';
import LasLogo from '../../assets/lasglogo.png';
import './luc.scss';
import LUCForm from '../../components/Forms/LUC';

const LUC = () => {
  return (
    <div className='pensions'>
      <MetaTags>
        <title>Land Use Charge Remittance - PayThru&trade;</title>
        <meta id="meta-description" name="description" content="Lagos State Land Use Charge. Pay easily in few steps." />
        <meta id="og-title" property="og:title" content="Land Use Charge Remittance - PayThru&trade;" />
      </MetaTags>
      <div id="header"/>
      <FormWrapper
        title="Lagos State Land Use Charge"
        logo={LasLogo}
        overrideLeftStyle={{backgroundImage: `url(${LUCImage})`}}
      >
        <LUCForm />
      </FormWrapper>
    </div>
  )
};

export default LUC;
