import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSelect, SelectComponent } from '../../../../components/Input';
import SupportSVG from '../../../../assets/svgs/dashboardsvg/support.svg'
import LogoutSVG from '../../../../assets/svgs/dashboardsvg/logout.svg'
import './sideNav.scss';

export const NavItem = ({item, active, onClick, overrideClass}) => {
  return (
    <button
      onClick={() => onClick(item.route)}
      className={`nav-item ${overrideClass} ${active ? 'active' : ''}`}
    >
      <img src={item.icon} alt="Icon" />
      <span>{item.name}</span>
    </button>
  )
}

const defaultNavItems = [
  {
    name: 'Support',
    icon: SupportSVG,
    onClick: () => {}
  },
  {
    name: 'Log Out',
    icon: LogoutSVG,
    onClick: () => {}
  }
]

const SideNav = (props) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const handleNavigate = (link) => {
    navigate(link);
  };

  const handleIsActive = (name) => {
    return pathname.search(name.toLowerCase()) >= 0;
  };
  console.log(props.product)
  return (
    <div className='side-nav'>
      <div>
        <SelectComponent
          className="input"
          placeholder=""
          name="product"
          data={props.products}
          value={props.product}
          onChange={(name, val) => {
            props.setProduct(val)
          }}
        />
        <div>
          {props.navItems && props.navItems.map((item, index) => (
            <NavItem
              key={item + index} 
              item={item} 
              active={handleIsActive(item.name.toLowerCase())} 
              onClick={handleNavigate}
            />
          ))}
        </div>
      </div>
      <div>
        {defaultNavItems.map((item, index) => (
          <NavItem key={item + index} item={item} active={false} onClick={() => {}} />
        ))}
      </div>
    </div>
  );
};

export default SideNav;
