import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import './contactus.css';

function ContactUs() {  
  const [data, setData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  const handleDataChange = (field, value) => {
    return setData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }
  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const callAction = axios.post('https://sheet.best/api/sheets/4cf25bba-8e69-4282-ba8f-bffffc490ce3', data);
    toast.promise(
      callAction,
       {
         loading: 'Saving...',
         success: () => {
           setLoading(false);
           setData({
            name: '',
            phone: '',
            email: '',
            message: ''
           });
          return 'Message saved!'
        },
        error: () => {
          setLoading(false);
          return 'Could not save.'
        },
       },
       {
        style: {
          minWidth: '250px',
          fontWeight: 'bold',
        },
      }
     );
  };

  return ( 
    <section className="contact-us" id='contact-us'>
      <div className="content">
        <h1>Contact Us</h1>
        <p>Our sales and tech support teams are readily available to answer any question you might have regarding integration, onboarding, transactions and others. Please get in touch today.</p>
        <p>We are located at All Seasons Plaza, 24 Lateef Jakande road Agidingbi, Ikeja Lagos.</p>
      </div>
      <form>
        <input value={data.name} onChange={({target}) => handleDataChange('name', target.value)} type="text" placeholder="Name" required />
        <input value={data.email} onChange={({target}) => handleDataChange('email', target.value)} type="text" placeholder="Email" required pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' />
        <input value={data.phone} onChange={({target}) => handleDataChange('phone', target.value)} type="text" placeholder="Phone Number" required pattern='[0-9]{11}' />
        <textarea value={data.message} onChange={({target}) => handleDataChange('message', target.value)} name="" id="" cols="30" rows="10" placeholder="Message" required></textarea>
        <button onClick={handleSubmit}>{loading ? 'Loading...' : 'Submit'}</button>
      </form>
    </section>
  );
}

export default ContactUs;
