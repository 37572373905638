import { useState } from "react";
import Button from "../../Button";
import FormWrapper from "../../FormWrapper";
import Input from "../../Input";
import './debitRegister.scss';

const DebitRegisterForm = () => {
  const [step, setStep] = useState(0);
  const isFirst = step === 0;
  const isSecond = step === 1;
  const renderStep = () => {
    return (
      <div className="stepper">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
      </div>
    )
  }

  const handleNext = () => {
    if (isFirst) {
      setStep(1);
    } else {
      setStep(0);
    }
  }

  return (
    <FormWrapper title="Debit Setup" subTitle={renderStep()}>
      <div className="form">
        {
          step === 0 && (
            <div className="form-wrapper">
              <p>Business Information</p>
              <div className="form-group">
                <Input
                  label="Business Name"
                  className="input"
                  name="businessName"
                />
                <Input
                  label="Contact Name" 
                  className="input" 
                  name="contactName"
                />
              </div>
              <div className="form-group">
                <Input 
                  label="Phone" 
                  className="input"
                  name="phone"
                />
                <Input
                  label="Business Address" 
                  className="input"
                  name="businessAddress"
                />
              </div>
              <div className="form-group">
                <Input
                  label="Email Address"
                  className="input"
                  name="email"
                />
                <Input
                  label="TIN"
                  className="input"
                  name="tin"
                />
              </div>
            </div>
          )
        }
        {
          step === 1 && (
            <div className="form-wrapper">
              <p>Banking Information</p>
              <div className="form-group">
                <Input
                  label="Bank Name"
                  className="select"
                />
              </div>
              <div className="form-group">
                <Input
                  label="Account Number" 
                  className="input"
                  name="accountNumber"
                />
                <Input
                  label="Account Name" 
                  className="input"
                  name="accountName"
                />
              </div>
            </div>
          )
        }
        <div className="form-group btn-wrapper">
          <Button onClick={handleNext} label={step === 0 ? "Next" : "Previous"} className="input" />
          <Button label="Submit" className="input submitBtn" />
        </div>
      </div>
    </FormWrapper>
  );
};

export default DebitRegisterForm;
