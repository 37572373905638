import { Outlet } from "react-router-dom";
import './bank.scss';

const Banks = () => {
  return (
    <div className="bank-wrapper">
      <Outlet />
    </div>
  );
};

export default Banks;
