import './formWrapper.scss';


const FormWrapper = ({
  title,
  subTitle,
  children,
  logo,
  overrideLeftStyle
}) => {
  return (
    <div className='wrapper'>
      {
        title && (
          <div className='left' style={overrideLeftStyle}>
            <div className='underlay' />
            {logo && <img src={logo} alt="" />}
            <h3>{title}</h3>
            <div>{subTitle}</div>
          </div>
        )
      }
      <div className='right'>
        {children}
      </div>
    </div>
  );
};

export default FormWrapper;
