import { Outlet } from 'react-router-dom';
import './bodywrapper.scss';

const BodyWrapper = () => {
  return (
    <div className="body-wrapper">
      <Outlet />
    </div>
  )
};

export default BodyWrapper;
