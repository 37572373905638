import { useMemo, useState } from "react";
import Table from "../../../../../../components/TableBuilder";
import { makeData } from "../../../../../../components/TableBuilder/makeData";
import { fuzzySort } from "../../../../../../utils/table";

const ListProduct = () => {
  const [data] = useState(() => makeData(50))

  const columns = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        header: () => <span>Name</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Has Agent</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'age',
        header: () => 'Descriptions',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'age',
        header: () => 'Remarks',
        footer: props => props.column.id,
      },
      {
        accessorFn: row => `${row.firstName} ${row.lastName}`,
        id: 'fullName',
        header: 'Has Packages',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Tax Commission</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Charges by User</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Fee Type</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Product State</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>Classification</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'visits',
        header: () => <span>PC Enabled</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id,
      },
    ],
    []
  )

  return (
    <div className="product-list">
      <Table columns={columns} data={data} />
    </div>
  );
};

export default ListProduct;
