import { useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../Button';
import Input from '../../Input';
import Utils, {getSignature} from '../../../Utils';
import { getService } from '../../../services';

import './luc.scss';

const LUCForm = () => {
  const navigate = useNavigate();
  const successKey = useLocation().search.split('=')[1];
  const currency = '₦';
  const url = Utils.providers.endpointProviderFor("Pension");
  // const url = `http://sandbox.paythru.ng/`;
  const [step, setStep] = useState(0);
  const [response, setResponse] = useState({});
  const [data, setData] = useState({
    paymentCode: '',
  });

  const handleDataChange = useCallback((field, value) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }, []);

  useEffect(() => {
    const savedKey = localStorage.getItem('lucSuccess');
    const savedPaymentCode = localStorage.getItem('pCode');
    if (successKey && savedKey && (savedKey === successKey) && savedPaymentCode) {
      console.log('Payment in Progress');
      getPaymentDetails(savedPaymentCode);
    }
  }, [successKey]);

  const handleNext = () => {
    setStep(step - 1);
    successKey && navigate('/luc');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getPaymentDetails(data.paymentCode);
  }

  const getPaymentDetails = async (paymentCode) => {
    const timeStamp =  Date.now();
    const secret = process.env.REACT_APP_CLIENT_SECRET;
    getSignature(`${timeStamp}${secret}`).then(sign =>
      getService(
        `${url}lasg/luc/payment/${paymentCode}/status`,
        (data) => {
          setResponse(data);
          localStorage.setItem('lucSuccess', data.successIndicator);
          localStorage.setItem('pCode', paymentCode);
          setStep(1);
        },
        'Payment details Loaded!',
        'Error loading details or No record found!',
        'Getting payment details...',
        timeStamp,
        sign
        ,
    ))
  };

  const notPaid = (response.code === 0 && response.payLink && response.amountDue) && !successKey;
  return (
    <div className='luc-form'>
      <div className="form">
        {
          step === 0 && (
            <form className="form-wrapper" onSubmit={handleSubmit}>
              <p>Payment Details</p>
              <div className="form-group">
                <Input
                  label="Enter Bank Payment Code"
                  className="select"
                  name="paymentCode"
                  required
                  value={data.paymentCode}
                  onChange={handleDataChange}
                />
              </div>
              <div className="form-group btn-wrapper">
                <Button btnActionType="submit" label="Submit" className="input submitBtn" />
              </div>
            </form>
          )
        }
        {
          step === 1 && (
            <div className="form-wrapper">
              <p className='header-title'>Payment Details</p>
              <div className="form-group details-wrapper">
                <p className='title'>Bank Payment Code</p>
                <p className='value'>{response.bankPaymentCode}</p>
              </div>
              <div className="form-group details-wrapper">
                <p className='title'>Payer Name</p>
                <p className='value'>{response.payerName}</p>
              </div>
              {
                (response.amountDue && !successKey) && (
                  <div className="form-group details-wrapper">
                    <p className='title'>Amount Due</p>
                    <p className='value'>{currency}{response.amountDue}</p>
                  </div>
                )
              }
              <div className='previous-download'>
                {
                  notPaid && (
                    <a href={response.payLink} target="_blank" rel="noreferrer">
                      Pay Outstanding now.
                    </a>
                  )
                }
              </div>
              {
                response.paymentRecords && (
                  <div className='receipt-wrapper'>
                    <p className='receipt-title'>Receipts.</p>
                    <div className='receipts'>
                      {
                        response.paymentRecords.map((record) => (
                          <div className='receipt'>
                            <div>
                              <p>{currency}{record.amountPaid}</p>
                              <p>{record.paymentDate.split('T')[0]}</p>
                            </div>
                            <a href={record.receiptUrl} target="_blank" rel="noreferrer">
                              Download Receipt.
                            </a>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              <div className="form-group btn-wrapper">
                <Button onClick={handleNext} label="Go Back" className="input" />
                <Button onClick={() => {
                  setStep(0);
                  handleDataChange('paymentCode', '');
                  successKey && navigate('/luc');
                }} label="Done" className="input submitBtn" />
              </div>              
            </div>
          )
        }
      </div>
    </div>
  );
};

export default LUCForm;
