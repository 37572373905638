import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PDFDownloadLink} from '@react-pdf/renderer';
import PensionImage from '../../assets/pensionForm.jpg'
import toast from 'react-hot-toast';
import Receipt from '../../components/PensionReceipt';
import Input from '../../components/Input';
import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';
import './pensionStatus.scss';


const url = process.env.REACT_APP_LIVE_API_BASE_URL;
const apiKey = `Paythru ${process.env.REACT_APP_API_KEY}`;

const DownloadPensionReceipt = () => {
  const employerCode = useParams().employerCode;
  const [status, setStatus] = useState(false);
  const [data, setData] = useState({});
  const [payData, setPayData] = useState({
    transactionId: '',
    employerCode: ''
  });

  useEffect(() => {
    setPayData((prev) => ({
      ...prev,
      employerCode: employerCode || '',
    }))
  }, [employerCode]);



  const fetchReceipt = async (event) => {
    event.preventDefault();
    const getEmployer = axios.get(`${url}api/v1/core/collection/pension/paymentstatus/${payData.employerCode}/${payData.transactionId}`, {
      headers: {
        'Authorization': apiKey
      }
    });
    toast.promise(
      getEmployer,
      {
        loading: 'Getting payment receipt...',
        success: (resp) => {
          setData(resp.data);
          setStatus(true);
          return 'Receipt fetched successfully'
        },
        error: () => {
          return 'Error getting Receipt'
        },
      },
      {
        style: {
          minWidth: '250px',
          fontWeight: 'bold',
        },
      }
    );
  };

  const handleDataChange = useCallback((field, value) => {
    setPayData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }, []);

  return (
    <div className='pensions'>
      <div id="header"/>
      <FormWrapper
        title="Pension Payment"
        overrideLeftStyle={{backgroundImage: `url(${PensionImage})`}}
      >
        <form className="form-wrapper" onSubmit={fetchReceipt}>
          <p>Download Previous Receipts</p>
          {
            status ? (
              <div className='download-btn-wrapper'>
                <PDFDownloadLink
                  document={<Receipt data={{data, payData}} />}
                  fileName={`${payData.transactionId || data.transactionId}_pension_pay.pdf`}
                >
                  {({ loading }) => (loading ? 
                    <Button label="Loading Receipt..." type="button" className="submitBtn" /> : 
                    <Button label="Download Receipt" type="button" className="submitBtn" />)}
                </PDFDownloadLink>
              </div>
            ) : (
              <div className="form-group">
                <Input
                  label="Employer Code"
                  className="select"
                  name="employerCode"
                  required
                  value={payData.employerCode}
                  onChange={handleDataChange}
                />
                <Input
                  label="Transaction ID"
                  className="select"
                  name="transactionId"
                  required
                  value={payData.transactionId}
                  onChange={handleDataChange}
                />
              </div>
            )
          }
          
          <div className="form-group btn-wrapper">
            <Link to="/payments/pensions#header" className="btn-link">
              <Button label="New Payment" type="button" />
            </Link>
            {status ? <span onClick={() => setStatus(false)}><Button type="button" label="New Receipt" className="input submitBtn" /></span> :  
            <Button btnActionType="submit" label="Get Receipt" className="input submitBtn" />}
          </div>
        </form>
      </FormWrapper>
    </div>
  )
};

export default DownloadPensionReceipt;
