import Button from "../../../Button";
import FormWrapper from "../../../FormWrapper";
import Input from "../../../Input";
import './createSub.scss';

const CreateSub = () => {
  return (
    <FormWrapper title="Create SubMerchant">
      <div className="form">
        <div className="form-wrapper">
          <div className="form-group">
            <Input
              label="Merchant Number"
              className="input"
              name="merchantNumber"
            />
            <Input
              label="Sub Merchant Name" 
              className="input" 
              name="subMerchantName"
            />
          </div>
          <div className="form-group">
            <Input 
              label="Email" 
              className="input"
              name="email"
            />
            <Input
              label="Phone Number" 
              className="input"
              name="phone"
            />
          </div>
          <div className="form-group">
            <Input
              label="Email Address"
              className="input"
              name="email"
            />
            <Input
              label="TIN"
              className="input"
              name="tin"
            />
          </div>
        </div>
        <div className="form-group btn-wrapper">
          <Button label="Submit" className="input submitBtn" />
        </div>
      </div>
    </FormWrapper>
  );
};

export default CreateSub;
