import { PDFDownloadLink } from '@react-pdf/renderer';
import PaymentInstruction from '../PaymentInstruction';
import Button from '../Button';

import './summaryModal.scss';

const currency = '₦';

const SummaryModal = ({summaries, closeModal}) => {
  return (
    <div className='summary-modal'>
      <div className='container'>
        <p className='summ-title'>Payment Summaries</p>
        <div className='summary-wrapper'>
          {
            summaries?.map((item, index) => (
              <Summary index={index} item={item} />
            ))
          }
        </div>
        <button onClick={() => closeModal(false)}>
          x
        </button>
      </div>
    </div>
  );
};

export default SummaryModal;

export const Summary = ({item, index}) => {
  return (
    <div key={index} className="summary">
      <p>{item.pfaName}</p>
      <div className='summ-detail'>
        <span>Total Employee: <b>{item.totalEmployee}</b></span>
        <span>Total Amount: <b>{currency}{item.total}</b></span>
        {
          item.offlinePaymentDetails ? (
            <PDFDownloadLink
              document={<PaymentInstruction data={item.offlinePaymentDetails} total={item.total} />}
              fileName={`${item.pfaName}_payment_info.pdf`}
            >
              {({ loading }) => (loading ? 
                <Button label="Loading" type="button" className="submitBtn" /> : 
                <Button label="Download Instructions" type="button" className="submitBtn downloadBtn" />)}
            </PDFDownloadLink>
          ) : <p>Error loading instructions</p>
        }
      </div>
    </div>
  );
}
