import { useState } from "react";
import Button from "../Button";
import Input, {SelectComponent} from "../Input";
import { CustomMultiSelect } from "../MultiSelect";
import './formBuilder.scss';


export const FormBuilder = ({
  data, showSteps, overrideFormStyle, state,
}) => {
  const [step, setStep] = useState(0);
  const [stateData, setData] = useState({
    ...state
  })
  const handleChange = (name, value) => {

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const renderInput = (item) => {
    return (
      <Input
        key={item.name}
        {...item}
        onChange={handleChange}
        value={stateData[item.name]}
      />
    )
  };

  const renderTextArea = (item) => {
    return (
      <textarea
        key={item.name}
        id="" 
        cols={30} 
        rows={10} 
        {...item}
        value={stateData[item.name]}
        onChange={({target}) => handleChange(target.name, target.value)}
      />
    )
  };

  const renderSelect = (item) => {
    return (
      <SelectComponent 
        key={item.name}
        value={stateData[item.name]} 
        {...item} 
        onChange={handleChange} 
      />
    )
  };

  const renderSearchableSelect = (item) => {
    return (
      <CustomMultiSelect 
        key={item.name}
        value={stateData[item.name]} 
        {...item} onChange={handleChange} 
      />
    )
  };

  const renderCheckbox = (item) => {
    return (
      <input
        key={item.name}
        value={stateData[item.name]} 
        type="checkbox" 
        name="" 
        id="" 
        onChange={({target}) => handleChange(target.name, target.value)} />
    )
  };

  const renderRadioBtn = (item) => {
    <input key={item.name} value={stateData[item.name]} type="radio" name="" id="" onChange={({target}) => handleChange(target.name, target.value)} />
  };

  const renderFormItems = (item) => {
    switch (item.type) {
      case 'text' || 'email' || 'password' || 'date' || 'file':
        return renderInput(item);
      case 'select':
        return renderSelect(item);
      case 'checkbox':
        return renderCheckbox(item);
      case 'radio':
        return renderRadioBtn(item);
      case 'textarea':
        return renderTextArea(item);
      case 'searchAble':
        return renderSearchableSelect(item);
      default:
        return renderInput(item);
    }
  }

  const renderForm = (formItems) => {
    return (
      <div>
        {
          formItems.map((item) => renderFormItems(item))
        }
      </div>
    );
  }

  const renderSteps = () => {
    return (
      <div className="steps">
        {
          data.map((i, index) => {
            return (
            <div key={index} className={`step ${step === index ? 'selected' : ''}`}>
              <div className="sphere" />
              <div className="line" />
            </div>
          )})
        }
      </div>
    )
  }

  const isOneStep = step === (data.length - 1);

  return (
    <div className="form-wrapper">
      {
        data.map((formData, index) => {
          if (index === step) {
            return (
              <form key={index} onSubmit={() => {
                if (step !== (data.length - 1)) {
                  setStep(step + 1);
                }
              }}>
                <h3>{formData.title}</h3>
                <h4>{formData.subTitle}</h4>
                {
                  data.length > 1 && showSteps && renderSteps()
                }
                <div>
                  {
                    renderForm(formData.formItems)
                  }
                </div>
                <div className="btn-wrapper">
                  {
                    !isOneStep && (
                      <Button
                        label={"Prev"} 
                        type={"text"}
                        disabled={step === 0}
                        onClick={() => {
                          setStep(step - 1);
                        }}
                      />
                    )
                  }
                  <Button
                    label={isOneStep ? "Submit" : "Next"} 
                    type={isOneStep ? "flat" : "text"} 
                    btnActionType={"submit"}
                    overrideStyle={isOneStep ? {width: '100% !important'} : {}}
                  />
                </div>
              </form>
            )
          }
          return null;
        })
      }
    </div>
  )
};

export default FormBuilder;
