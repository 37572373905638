import Close from '../../assets/close.png';
import BlackCaret from '../../assets/blackCaret.png';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import docs from '../../docs';

import './documentation.scss';

function Documentations() {
  const pathname = useLocation().pathname;
  const [product, setProduct] = useState('cardFree');
  const [documentation, setDocumentaton] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [openMenu, setOpenMenu] = useState(true);
  const navItems = Object.values(docs.products);

  const getSelectedRoute = (route) => {
    return pathname === route;
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 620) {
        setOpenMenu(false);
        setIsMobile(true);
      } else {
        setOpenMenu(true);
        setIsMobile(false);
      }
    });
    return () => window.removeEventListener("resize", () => {
      if (window.innerWidth <= 620) {
        setOpenMenu(false);
      } else {
        setOpenMenu(true);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 620) {
      setOpenMenu(false);
      setIsMobile(true);
    } else {
      setOpenMenu(true);
      setIsMobile(false);
    }
  }, []);

  return (
    <div className="documentation">
      {
        openMenu && (
          <nav className='side-nav'>
            <ul className='side-nav-ul'>
              {
                navItems.map((item, index) => (
                  <CustomNavItem
                    index={index}
                    key={item.name}
                    item={item}
                    subItems={item.sections}
                    isMobile={isMobile}
                    setOpenMenu={setOpenMenu}
                    getSelectedRoute={getSelectedRoute}
                    setProduct={setProduct}
                    setDocumentaton={setDocumentaton}
                  />
                ))
              }
            </ul>
          </nav>
        )
      }
      <button className='open-nav' onClick={() => setOpenMenu(!openMenu)}>
        <img src={openMenu ? Close : BlackCaret} alt="" />
      </button>
      <Documentation selectedDocs={documentation} />
    </div>
  );
}

const Documentation = ({selectedDocs}) => {
  const renderObj = (value, replacer = null, space = 2 ) => (
    <pre>
      <code>{JSON.stringify(value, replacer, space)}</code>
    </pre>
  )

  const {documentation} = selectedDocs;
  return (
    <div className="doc-wrapper">
      <h2>{selectedDocs?.name}</h2>
      {
        documentation?.map((item) => (
          <div key={item.title}>
            <h3>{item.title}</h3>
            <div className="decriptions">
              {
                item?.description?.map((descriptionItem) => (
                  <p key={descriptionItem}>{descriptionItem}</p>
                ))
              }
            </div>
            <div>
              <p>{item?.bodyHeadling}</p>
              {
                item?.body?.map((bodyItem) => (
                  item?.bodyType === 'list' ? <ul key={bodyItem}>{bodyItem}</ul> : <p key={bodyItem}>{bodyItem}</p>
                ))
              }
            </div>
            <div>
              {
                item?.endpoints?.map((endpoint) => (
                  <div key={endpoint.title}>
                    <h4>{endpoint.title}</h4>
                    <h5>{endpoint.url}</h5>
                    <div className="decriptions">
                      {
                        endpoint?.description?.map((descriptionItem) => (
                          <p key={descriptionItem}>{descriptionItem}</p>
                        ))
                      }
                    </div>
                    {endpoint?.sampleRequest && (
                      <div>
                        <h5>Sample Request</h5>
                        {renderObj(endpoint?.sampleRequest)}
                      </div>
                    )}
                    {
                      endpoint?.requestDetails && endpoint?.requestDetails.length > 0 && (
                        <div>
                          <h5>Request Details</h5>
                          <div className="endpoint-table">
                            <div className="table-row header">
                              <span>name</span>
                              <span>
                                description
                              </span>
                              <span>Datatype</span>
                              <span>Remarks</span>
                            </div>
                            {
                              endpoint?.requestDetails?.map((detail) => (
                                <div key={detail.name} className="table-row">
                                  <span>{detail.name}</span>
                                  <span>
                                    {detail.description?.detail}
                                    {
                                      detail.description?.list?.map((listItem) => (<li key={listItem}>{listItem}</li>))
                                    }
                                  </span>
                                  <span>{detail.type}</span>
                                  <span>{detail.remarks}</span>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                    { endpoint?.sampleResponse && (
                      <div>
                        <h5>Sample Response</h5>
                        {renderObj(endpoint?.sampleResponse)}
                      </div>
                    )}
                    {
                      endpoint?.responseDescription && endpoint?.responseDescription.length > 0 && (
                        <div>
                          <h5>Response Details</h5>
                          <div className="endpoint-table">
                            <div className="table-row header">
                              <span>name</span>
                              <span>
                                description
                              </span>
                              <span>Datatype</span>
                              <span>Remarks</span>
                            </div>
                            {
                              endpoint?.responseDescription?.map((detail) => (
                                <div key={detail.name} className="table-row">
                                  <span>{detail.name}</span>
                                  <span>
                                    {detail.description?.detail}
                                    {
                                      detail.description?.list?.map((listItem) => (<li key={listItem}>{listItem}</li>))
                                    }
                                  </span>
                                  <span>{detail.type}</span>
                                  <span>{detail.remarks}</span>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                    
                  </div>
                ))
              }
            </div>
            <div className="conclusion">
              {
                item?.conclusions?.map((conclusion) => (<p key={conclusion}>{conclusion}</p>))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

const CustomNavItem = (
  {
    item,
    index,
    isMobile, 
    setOpenMenu,
    getSelectedRoute,
    setProduct,
    setDocumentaton,
  }
) => {
  const [showChildren, setShowChildren] = useState(true);
  const [selectedSection, setSection] = useState('Basics');

  useEffect(() => {
    if (index === 0) {
      const firstSection = item.sections[0]
      setSection(firstSection);
      setDocumentaton(item.docs[firstSection]);
    }
  }, [index, setSection, setDocumentaton, item]);

  if (item.sections && item.sections.length > 0) {
    return (
      <div
        onClick={() => {
          if (isMobile) {
            setOpenMenu(false);
            setProduct()
          }
        }}
        key={item.name}
        className={`nav-item ${getSelectedRoute(item.pata1h) ? 'selected-nav-item' : ''}`}
      >
        <li onClick={() => {
          setShowChildren(!showChildren);
        }}>
          <div className='item-wrapper'>
            <span>{item.name}</span>
          </div>
          <img 
            className='caret'
            src={BlackCaret}
            alt="^"
            style={{transform: showChildren ? `rotateX(0deg)` : `rotateX(-180deg)`}}
          />
        </li>
        {
          showChildren && (
            <div className='sub-items'>
              {
                item.sections.map((section) => (
                  <li
                    className={selectedSection === section ? 'active-subItem' : ''}
                    onClick={() => {
                      setSection(section);
                      setDocumentaton(item.docs[section]);
                    }}
                    key={section}>
                    {section}
                  </li>
                ))
              }
            </div>
          )
        }
      </div>
    );
  }

  return (
    <NavLink
      onClick={() => {
        if (isMobile) {
          setOpenMenu(false);
        }
      }}
      key={item.name} 
      to={'/'} 
      className={`nav-item ${getSelectedRoute(item.name) ? 'selected-nav-item' : ''}`}
    >
      <li>
        <div className='item-wrapper'>
          <span>{item.name}</span>
        </div>
      </li>
    </NavLink>
  )
}

export default Documentations;
