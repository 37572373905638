import { useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EnterpriseImage from '../../../../assets/enterprise.png';
import Button from '../../../../components/Button';
import FormWrapper from '../../../../components/FormWrapper';
import Input from '../../../../components/Input';
// import Utils, {getSignature} from '../../../Utils';
// import { getService } from '../../../services';

import './login.scss';
import { scrollToTop } from '../../../../Utils';
import { Link } from 'react-router-dom';

const Login = () => {
  // const navigate = useNavigate();
  const successKey = useLocation().search.split('=')[1];
  // const url = Utils.providers.endpointProviderFor("Pension");
  // const url = `http://sandbox.paythru.ng/`;
  // const [response, setResponse] = useState({});
  const [data, setData] = useState({
    paymentCode: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleDataChange = useCallback((field, value) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }, []);

  useEffect(() => {
    const savedKey = localStorage.getItem('lucSuccess');
    const savedPaymentCode = localStorage.getItem('pCode');
    if (successKey && savedKey && (savedKey === successKey) && savedPaymentCode) {
      console.log('Payment in Progress');
      getPaymentDetails(savedPaymentCode);
    }
  }, [successKey]);

  const handleSubmit = (event) => {
    event.preventDefault();
    getPaymentDetails(data.paymentCode);
  }

  const getPaymentDetails = async (paymentCode) => {
    // const timeStamp =  Date.now();
    // const secret = process.env.REACT_APP_CLIENT_SECRET;
    // getSignature(`${timeStamp}${secret}`).then(sign =>
    //   getService(
    //     `${url}lasg/luc/payment/${paymentCode}/status`,
    //     (data) => {
    //       setResponse(data);
    //       localStorage.setItem('lucSuccess', data.successIndicator);
    //       localStorage.setItem('pCode', paymentCode);
    //       setStep(1);
    //     },
    //     'Payment details Loaded!',
    //     'Error loading details or No record found!',
    //     'Getting payment details...',
    //     timeStamp,
    //     sign
    //     ,
    // ))
  };

  return (
    <FormWrapper
      title="   "
      logo={null}
      overrideLeftStyle={{backgroundImage: `url(${EnterpriseImage})`}}
    >
      <div className='login-form'>
        <div className="form">
          <form className="form-wrapper" onSubmit={handleSubmit}>
            <p>Enterprise Login</p>
            <div className="form-group">
              <Input
                label="Email"
                placeholder="example@enterprise.paythru"
                className="select"
                name="email"
                required
                value={data.email}
                onChange={handleDataChange}
              />
            </div>
            <div className="form-group">
              <Input
                label="Password"
                placeholder="**********"
                className="select"
                name="password"
                required
                value={data.password}
                onChange={handleDataChange}
              />
            </div>
            <div className="form-group btn-wrapper">
              <Button btnActionType="submit" label="Login" className="input submitBtn" />
              <Link to="/enterprise/forgot-password">Forgot Password?</Link>
            </div>
          </form>
        </div>
      </div>
    </FormWrapper>
  );
};

export default Login;
