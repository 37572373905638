import axios from 'axios';
import toast from 'react-hot-toast';
const apiKey = `Paythru ${process.env.REACT_APP_API_KEY}`;

export const getService = (url, handleDone, successMsg, failureMsg, loadingMsg, timeStamp="", signature='') => {
  const getCall = axios.get(url, {
    headers: {
      'Authorization': apiKey,
      'Timestamp': timeStamp,
      'Signature': signature
    }
  });
  toast.promise(
    getCall,
     {
       loading: loadingMsg,
       success: (resp) => {
        if (resp.data.code !== 200 && resp.data.code !== 0) {
          throw new Error(resp.data.message);
        }
        handleDone(resp?.data);
        return (
          <div className='toast-close-btn'>
            {successMsg}
            <button onClick={() => toast.dismiss()}>x</button>
          </div>
        );
      },
      error: (err) => {
        return (
          <div className='toast-close-btn'>
            {err?.message || failureMsg}
            <button onClick={() => toast.dismiss()}>x</button>
          </div>
        );
      },
     },
     {
      style: {
        fontSize: '20px',
        minWidth: '250px',
        fontWeight: 'bolder',
      },
      duration: 15000
    }
  );
}

export const postService = (url, payload, handleDone, successMsg, failureMsg, loadingMsg, timeStamp="", signature='') => {
  const postCall = axios.post(url, payload, {
    headers: {
      'Authorization': apiKey,
      'Timestamp': timeStamp,
      'Signature': signature
    }
  });
  toast.promise(
    postCall,
     {
       loading: loadingMsg,
       success: (resp) => {
        if (resp.data.code !== 200 && resp.data.code !== 0) {
          throw new Error();
        }
        handleDone(resp?.data);
        return (
          <div className='toast-close-btn'>
            {successMsg}
            <button onClick={() => toast.dismiss()}>x</button>
          </div>
        );
      },
      error: (error) => {
        return (
          <div className='toast-close-btn'>
            {error.toString() || failureMsg}
            <button onClick={() => toast.dismiss()}>x</button>
          </div>
        );
      },
     },
     {
      style: {
        fontSize: '20px',
        minWidth: '250px',
        fontWeight: 'bolder',
      },
      duration: 15000
    }
  );
}

