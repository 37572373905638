import { useNavigate } from "react-router-dom";
import './header.scss';
import { NavItem } from '../SideNav';
import AddSVG from '../../../../assets/dashboard/new.png';
import DetailsImg from '../../../../assets/dashboard/details.png';
import ListImg from '../../../../assets/dashboard/list.png';
import RequestImg from '../../../../assets/dashboard/request.png';
import useProduct from '../../../../hooks/useProduct';

const secNav = {
  debit: {
    overview: [],
    banks: [
      {
        name: 'All Banks',
        route: '/enterprise/dashboard/debit/banks',
        icon: ListImg
      },
      {
        name: 'Add Bank',
        route: '/enterprise/dashboard/debit/banks/add',
        icon: AddSVG
      },
    ],
    mandates: [
      {
        name: 'All Mandates',
        route: '/enterprise/dashboard/debit/mandates/',
        icon: ListImg
      },
      {
        name: 'Request Mandate',
        route: '/enterprise/dashboard/debit/mandates/request',
        icon: RequestImg
      },
      {
        name: 'New Mandate',
        route: '/enterprise/dashboard/debit/mandates/add',
        icon: AddSVG
      },
      {
        name: 'Mandate Details',
        route: '/enterprise/dashboard/debit/mandates/details',
        icon: DetailsImg
      },
    ],
    products: [
      {
        name: 'All Products',
        route: '/enterprise/dashboard/debit/products/',
        icon: ListImg
      },
      {
        name: 'Create Product',
        route: '/enterprise/dashboard/debit/products/add',
        icon: AddSVG
      },
      {
        name: 'Product Details',//this page with display product details and packages CRUD, 
        route: '/enterprise/dashboard/debit/products/details',
        icon: DetailsImg
      }
    ],
    settings: [],
  },
  credit: {
    overview: [],
    banks: [
      {
        name: 'All Banks',
        route: '/enterprise/dashboard/debit/banks/add',
        icon: AddSVG
      },
      {
        name: 'Add Bank',
        route: '/enterprise/dashboard/debit/banks/add',
        icon: AddSVG
      },
    ],
    mandates: [
      {
        name: 'All Mandates',
        route: '/enterprise/dashboard/debit/mandates/request',
        icon: AddSVG
      },
      {
        name: 'Request Mandate',
        route: '/enterprise/dashboard/debit/mandates/request',
        icon: AddSVG
      },
      {
        name: 'New Mandate',
        route: '/enterprise/dashboard/debit/mandates/new',
        icon: AddSVG
      },
      {
        name: 'Mandate Details',
        route: '/enterprise/dashboard/debit/mandates/new',
        icon: AddSVG
      },
    ],
    settings: [],
  },
}

const Header = () => {
  const navigate = useNavigate();
  const {product, title} = useProduct();
  const productObj = secNav[product];
  const secNavItems = productObj ? productObj[title] : [];
  // console.log(product, '-=-=-=-=-',title, secNavItems)
  // const secNavItms = secNav[product][title];
  return (
    <div className="header">
      <h3>{title}</h3>
      <div className='sec-nav'>
        {
          secNavItems.map((item) => (
            <NavItem
              item={item}
              onClick={() => navigate(item.route)} 
              active={false}
              overrideClass={'item'}
              key={item.route}
            />
          ))
        }
      </div>
    </div>
  );
};

export default Header;
