import { HashLink } from 'react-router-hash-link';
import Future from '../../assets/future.png';
import MicroFuture from '../../assets/micropension.png';
import './payments.scss';

const Payments = () => {
  const paymentItems = [
    {
      name: 'Pension Contribution',
      description: 'Make regular pension payment to your holder easily anytime. Remove the bottle neck of manual processing.',
      link: 'pensions#header',
      logo: Future,
    },
    // {
    //   name: 'Micro Pension',
    //   description: 'Make regular pension payment to your holder easily anytime. Remove the bottle neck of manual processing.',
    //   link: 'micro-pensions#header',
    //   logo: MicroFuture,
    // }
  ]
  return (
    <div className='payments'>
      <div id="header"/>
      <div className="solution-wrapper">
        {
          paymentItems.map((item) => (
            <HashLink to={item.link} className="solution" smooth key={item.link}>
              <div>
                <img src={item.logo} alt="Pension" style={{width: '24px', height: '24px'}} />
                <h2>{item.name}</h2>
              </div>
              <p>{item.description}</p>
              <button disabled = {item.name=="Micro Pension"}>Make Payment</button>
            </HashLink>
))
        }
      </div>
    </div>
  );
};

export default Payments;
