import { useEffect, useState } from 'react';
import Logo from '../../assets/paythruLogo.png';
import Close from '../../assets/close.png';
import Menu from '../../assets/menu.png';
import './nav.scss';
import NavItems from '../NavItems';
import { useLocation } from 'react-router-dom';

function Nav() {
  const [navStyle, setNavStyle] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const {pathname} = useLocation();
  
  useEffect(() => {
    if (pathname === '/') {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    } else {
      setNavStyle({
        backgroundColor: '#ffffff',
      });
    }
  }, [pathname]);

  const handleScroll = (event) => {
    let scrollTop = window.pageYOffset;
    if (scrollTop > 100) {
      setNavStyle({
        backgroundColor: '#F6F9FFec',
      });
    } else {
      setNavStyle({
        backgroundColor: 'transparent'
      });
    }
  }

  console.log(pathname)

  if (pathname && pathname.search('enterprise/dashboard') >= 0) {
    return null;
  }

  
  return (
    <nav style={navStyle} id="nav">
      <div className="left">
        <img src={Logo} alt="Paythru" />
        <ul className="nav-item-wrapper">
          <NavItems type="main" />
        </ul>
      </div>
      <button className='loginBtn'><a href="http://apps.paythru.ng/Identity/login" target="_blank" rel="noreferrer">Login</a></button>
      <img src={Menu} alt="" className='openBtn' onClick={() => setOpenMenu(true)} />
      {
        openMenu && (
          <div className='mobilenav-item'>
            <img src={Close} alt="" className='closeBtn' onClick={() => setOpenMenu(false)} />
            <ul className="mobile-nav-item-wrapper">
              <NavItems type="main" />
              <button className='loginBtnMobile'><a href="http://apps.paythru.ng/Identity/login" target="_blank" rel="noreferrer">Login</a></button>
            </ul>
          </div>
        )
      }
    </nav>
  );
}

export default Nav;
