import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import toast from 'react-hot-toast';
import PensionImage from '../../assets/pensionForm.jpg'
import Receipt from '../../components/PensionReceipt';
import Button from '../../components/Button';
import FormWrapper from '../../components/FormWrapper';
import './pensionStatus.scss';
import Utils from '../../Utils';



// const url = process.env.REACT_APP_API_BASE_URL;
// const apiKey = process.env.REACT_APP_API_KEY;

const PensionStatus = () => {
  const url = `${Utils.providers.endpointProviderFor("Pension")}api/v1/core/collection/`;
  // const url = `${process.env.REACT_APP_TEST_API_BASE_URL}coll1ection/`;
  const apiKey = `Paythru ${process.env.REACT_APP_API_KEY}`;
  const [searchParam] = useSearchParams();
  const transactionId = localStorage.getItem('transactionID');
  const employerCode = localStorage.getItem('employerCode');
  const [status, setStatus] = useState(true);
  const [successMsg, setSuccessMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchReceipt = useCallback(() => {
    
    const getEmployer = axios.get(`${url}pension/paymentstatus/${employerCode}/${transactionId}`, {
      headers: {
        'Authorization': apiKey
      }
    });
    toast.promise(
        getEmployer,
       {
         loading: 'Getting payment receipt...',
         success: (resp) => {
          setData(resp.data);
          setLoading(false);
          return 'Receipt fetched successfully'
        },
        error: () => {
          return 'Error getting Receipt'
        },
       },
       {
        style: {
          minWidth: '250px',
          fontWeight: 'bold',
        },
      }
     );
  }, [employerCode, transactionId])
 
  useEffect(() => {
    const successKeyLocal = localStorage.getItem('successKey');
    if (successKeyLocal && searchParam.get('status') === successKeyLocal) {
      setLoading(false);
      fetchReceipt()
      setSuccessMsg('Pension Payment successful');
      setStatus(true);
    } else if (transactionId && (successKeyLocal && searchParam.get('status') !== successKeyLocal)) {
      setLoading(false);
      setSuccessMsg('Pension payment failed!');
      setStatus(false);
    } else if (!transactionId && !successKeyLocal) {
      setLoading(false);
      setSuccessMsg('No Pension Payment in progress!');
      setStatus(false);
    }
    return () => {
      localStorage.removeItem('transactionID');
      localStorage.removeItem('successKey');
      localStorage.removeItem('employerCode');
    }
  }, [fetchReceipt, transactionId, searchParam]);

  return (
    <div className='pensions'>
      <div id="header"/>
      <FormWrapper
        title="Pension Payment"
        overrideLeftStyle={{backgroundImage: `url(${PensionImage})`}}
      >
        {
          loading ? <p>Loading...</p> : (
            <div>
              {
                status ? (
                  <>
                    <h3>{successMsg}</h3>
                    <p>Transaction ID: <b>{transactionId}</b></p>
                    <PDFDownloadLink
                      document={<Receipt data={{...data, employerCode, transactionId}} />}
                      fileName={`${transactionId}_pension_pay.pdf`}
                    >
                      {({ loading }) => (loading ? 
                        <Button label="Loading Receipt..." type="button" className="submitBtn" /> : 
                        <Button label="Download Receipt" type="button" className="submitBtn" />)}
                    </PDFDownloadLink>
                  </>
                ) : (
                  <>
                    <h3>No Pension Payment in progress.</h3>
                    <Link to={`/pension/receipt/download/${data.employerCode}`}>
                      Download Receipt for a previous payment.
                    </Link>
                  </>
                )
              }
            </div>
          )
        }
        <Link to="/payments/pensions#header" className="button-link">
          <Button label="Make another Payment" type="button" className="submitBtn" />
        </Link>
      </FormWrapper>
    </div>
  )
};

export default PensionStatus;
