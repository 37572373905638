import FormBuilder from "../../../../../../components/Formbuilder";

const NewProduct = () => {
  return (
    <div className="new-product-wrapper">
      <FormBuilder
            state={{
              bank: '',
              accountNumber: '',
              accountName: '',
            }}
            showSteps
            data={[
              {
                title: 'Create New Product',
                subTitle: '',
                formItems: [
                  {
                    label: 'Select Collection Bank',
                    name: 'bank',
                    type: 'searchAble',
                    errorText: 'Error Text',
                    data: ['UBA', 'GT Bank'],
                    required: true,
                  },
                  {
                    label: 'Product Name',
                    name: 'snumber',
                    type: 'text',
                    errorText: 'Error Text',
                    data: [],
                  },
                  {
                    label: 'Product Description',
                    name: 'mnumber',
                    type: 'text',
                    errorText: 'Error Text',
                    data: [],
                    required: true,
                  },
                  {
                    label: 'Product Remarks',
                    name: 'mnumber',
                    type: 'text',
                    errorText: 'Error Text',
                    data: [],
                    required: true,
                  }
                ]
              },
              {
                title: 'Create New Product',
                subTitle: '',
                formItems: [
                  {
                    label: 'Has Packages?',
                    name: 'bank',
                    type: 'select',
                    errorText: 'Error Text',
                    data: ['Yes', 'No'],
                    required: true,
                  },
                  {
                    label: 'Fee Type',
                    name: 'snumber',
                    type: 'select',
                    errorText: 'Error Text',
                    data: ['Type 1', 'Type 2', 'Type 3'],
                  },
                  {
                    label: 'Product Classification',
                    name: 'mnumber',
                    type: 'select',
                    errorText: 'Error Text',
                    data: ['Class 1', 'Class 2', 'Class 3'],
                    required: true,
                  },
                  {
                    label: 'Is Partial Collection Possible?',
                    name: 'mnumber',
                    type: 'select',
                    errorText: 'Error Text',
                    data: ['Yes', 'No'],
                    required: true,
                  },
                  {
                    label: 'Is User Responsible for Charges?',
                    name: 'mnumber',
                    type: 'select',
                    errorText: 'Error Text',
                    data: ['Yes', 'No'],
                    required: true,
                  }
                ]
              },
            ]}
          />
    </div>
  );
};

export default NewProduct;
