import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useProduct = () => {
  const [title, setTitle] = useState('');
  const [product, setProduct] = useState('');
  const {pathname} = useLocation();

  useEffect(() => {
    const paths = pathname.split('/');
    setProduct(paths[3]);
    setTitle(paths[4]);
  }, [pathname]);

  return {title, product};
};

export default useProduct;
