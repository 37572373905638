import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../assets/paythruLogo.png';

const currency = 'NGA';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
  },
  section: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px',
    margin: 20,
    padding: 10,
    position: 'relative',
  },
  logo: {
    width: 100,
    height: 30,
  },
  body: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },
  bodyItem: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '70%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
  },
  bookmark: {
    objectFit: 'contain',
    position: 'absolute',
    opacity: 0.1,
    height: '100%',
    width: '70%',
    marginLeft: 'auto',
    left: '15%',
    top: '10%'
  },
  container: {
    position: 'relative',
  },
  info: {
    width: '80%',
    textAlign: 'center',
    margin: 'auto',
  },
  reference: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  }
});

// Create Document Component
const PaymentInstruction = ({data, total}) => {
  if (!data) return null
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.container}>
          <Image src={Logo} style={styles.bookmark} />
          <View style={styles.section}>
            <Image src={Logo} style={styles.logo} />
            <Text style={{fontWeight: '900', fontSize: '22px'}}>Offline Pension Payment Instructions</Text>
          </View>
          <View style={[styles.bodyItem, styles.reference]}>
            <Text>Transaction Reference</Text>
            <Text style={{marginTop: '10px', fontWeight: '900'}}>{data.transactionReference}</Text>
          </View>
          <View style={styles.body}>
            <View style={styles.bodyItem}>
              <Text>Bank Name</Text>
              <Text>{data.bankName}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Account Name</Text>
              <Text>{data.accountName}</Text>
            </View>
            <View style={styles.bodyItem}>
              <Text>Account Number</Text>
              <Text>{data.accountNumber}</Text>
            </View>
            <View style={[styles.bodyItem, {marginTop: 30}]}>
              <Text>Total</Text>
              <Text style={{
                borderTop: '1px solid #000', 
                borderBottom: '1px solid #000', 
                width: 100, 
                paddingTop: 5, 
                paddingBottom: 5, 
                textAlign: 'right'
                }}
              >{currency} {`${total}`}</Text>
            </View>
          </View>
          <View style={[styles.body, {width: '80%', textAlign: 'center', margin: 'auto'}]}>
            <Text style={styles.info}>{data.paymentInstruction}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export default PaymentInstruction;
