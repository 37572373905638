import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Debit from './Pages/Debit/index';
import Home from './Pages/Home/index';
import NQR from './Pages/NQR/index';

import './App.css';
import NQRRegister from './Pages/NQRRegister';
import Footer from './components/Footer';
import Nav from './components/Nav';
import DownloadQRCode from './Pages/DownloadQR';
import CreateSubMerchant from './Pages/CreateSubMerchant';
import Payments from './Pages/Payments';
import PensionContribution from './Pages/PensionContribution';
import OnboardPage from './Pages/Onboard';
import DebitRegister from './Pages/DebitRegister';
import Documentations from './Pages/Documentation';
import PensionStatus from './Pages/PensionStatus';
import DownloadPensionReceipt from './Pages/DownloadPensionReceipt';
import MicroPension from './Pages/MicroPension';
import LUC from './Pages/LUC';
import Login from './Pages/Dashboards/Pages/Login';
import Privacy from  './Pages/Privacy'
import Dashboards from './Pages/Dashboards';
import Banks from './Pages/Dashboards/Pages/debit/Banks';
import Mandates from './Pages/Dashboards/Pages/debit/Mandates';
import Overview from './Pages/Dashboards/Pages/debit/Overview';
import Settings from './Pages/Dashboards/Pages/debit/Settings';
import Products from './Pages/Dashboards/Pages/debit/Products';
import CreditOverview from './Pages/Dashboards/Pages/credit/Overview';
import CreditMandates from './Pages/Dashboards/Pages/credit/Mandates';
import CreditBanks from './Pages/Dashboards/Pages/credit/Banks';
import CreditSettings from './Pages/Dashboards/Pages/credit/Settings';
import CreateBanks from './Pages/Dashboards/Pages/debit/Banks/Pages/CreateBank';
import AddBank from './Pages/Dashboards/Pages/debit/Banks/Pages/AllBanks';
import ListMandate from './Pages/Dashboards/Pages/debit/Mandates/Pages/List';
import NewMandate from './Pages/Dashboards/Pages/debit/Mandates/Pages/NewMandate';
import RequestMandate from './Pages/Dashboards/Pages/debit/Mandates/Pages/Request';
import MandateDetail from './Pages/Dashboards/Pages/debit/Mandates/Pages/Details';
import ListProduct from './Pages/Dashboards/Pages/debit/Products/Pages/List';
import NewProduct from './Pages/Dashboards/Pages/debit/Products/Pages/NewProduct';
import ProductDetail from './Pages/Dashboards/Pages/debit/Products/Pages/ProductDetails';
import Transactions from './Pages/Dashboards/Pages/debit/Mandates/Pages/Transactions';
import Settlements from './Pages/Dashboards/Pages/debit/Mandates/Pages/Settlements';
import Schedules from './Pages/Dashboards/Pages/debit/Mandates/Pages/Schedules';
import Invoices from './Pages/Dashboards/Pages/debit/Mandates/Pages/Invoices';

const dashboardsPages = [
  {
    name: 'Overview',
    component: Overview,
    type: 'debit'
  },
  {
    name: 'Mandates',
    component: Mandates,
    type: 'debit',
    children: [
      {
        name: 'Mandates',
        component: ListMandate,
        type: 'debit',
        route: '',
      },
      {
        name: 'Mandates',
        component: NewMandate,
        type: 'debit',
        route: 'Add',
      },
      {
        name: 'Mandates',
        component: RequestMandate,
        type: 'debit',
        route: 'Request',
      },
      {
        name: 'Mandates',
        component: MandateDetail,
        type: 'debit',
        route: 'Details',
      },
      {
        name: 'Mandates',
        component: Transactions,
        type: 'debit',
        route: 'Transactions',
      },
      {
        name: 'Mandates',
        component: Settlements,
        type: 'debit',
        route: 'Settlements',
      },
      {
        name: 'Mandates',
        component: Schedules,
        type: 'debit',
        route: 'Schedules',
      },
      {
        name: 'Mandates',
        component: Invoices,
        type: 'debit',
        route: 'Invoices',
      }
    ]
  },
  {
    name: 'Banks',
    component: Banks,
    type: 'debit',
    children: [
      {
        name: 'Banks',
        component: AddBank,
        type: 'debit',
        route: '',
      },
      {
        name: 'Banks',
        component: CreateBanks,
        type: 'debit',
        route: 'Add',
      }
    ]
  },
  {
    name: 'Settings',
    component: Settings,
    type: 'debit',
  },
  {
    name: 'Products',
    component: Products,
    type: 'debit',
    children: [
      {
        name: 'Products',
        component: ListProduct,
        type: 'debit',
        route: '',
      },
      {
        name: 'Products',
        component: NewProduct,
        type: 'debit',
        route: 'Add',
      },
      {
        name: 'Products',
        component: ProductDetail,
        type: 'debit',
        route: 'Details',
      }
    ]
  },
  {
    name: 'Overview',
    component: CreditOverview,
    type: 'credit'
  },
  {
    name: 'Mandates',
    component: CreditMandates,
    type: 'credit',
  },
  {
    name: 'Banks',
    component: CreditBanks,
    type: 'credit',
  },
  {
    name: 'Settings',
    component: CreditSettings,
    type: 'credit',
  },
]


function App() {
  return (
    <div className="App">
      <Nav />
      <Toaster position="top-right" />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/debit" exact element={<Debit />} />
        <Route path="/privacy" exact element={<Privacy />} />
        <Route path="/nqr" exact element={<NQR />} />
        <Route path="/nqr/register" exact element={<NQRRegister />} />
        <Route path="/nqr/getqrcode" exact element={<DownloadQRCode />} />
        <Route path="/nqr/submerchant" exact element={<CreateSubMerchant />} />
        <Route path="/payments" exact element={<Payments />} />
        <Route path="/onboard" exact element={<OnboardPage />} />
        <Route path="/debit/register" exact element={<DebitRegister />} />
        <Route path="/payments/pensions" exact element={<PensionContribution />} />
        <Route path="/payments/micro-pensions" exact element={<MicroPension />} />
        <Route path="/luc" exact element={<LUC />} />
        <Route path="/luc/status" exact element={<LUC />} />
        <Route path="/pension" exact element={<PensionContribution />} />
        <Route path="/micro-pension" exact element={<MicroPension />} />
        <Route path="/documentations" exact element={<Documentations />} />
        <Route path="/pension/payment" exact element={<PensionStatus />} />
        <Route path="/pension/receipt/download/:employerCode" element={<DownloadPensionReceipt />} />
        <Route path="/pension/receipt/download/" element={<DownloadPensionReceipt />} />
        <Route path="/enterprise/login" exact element={<Login />} />
        <Route path="/enterprise/dashboard" element={<Dashboards />}>
          {
            dashboardsPages.map((item) => (
              <Route
                key={item.name}
                path={`/enterprise/dashboard/${item.type}/${item.name.toLowerCase()}`} 
                element={<item.component />} 
              >
                {
                  item.children && item.children.map((child) => (
                    <Route
                      key={child.name}
                      path={`/enterprise/dashboard/${item.type}/${item.name.toLowerCase()}/${child.route.toLowerCase()}`} 
                      exact element={<child.component />} 
                    />
                  ))
                }
              </Route>
            ))
          }
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
