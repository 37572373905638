import './button.scss';


function Button(props) {
  return (
    <button
      className={`genericBtn ${props.className}`}
      onClick={() => {
        props.onClick && props.onClick()
      }}
      disabled={props.disabled}
      style={props.overrideStyle}
      type={props.btnActionType || 'button'}
    >
      {props.label}
    </button>
  );
}

export default Button;