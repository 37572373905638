import { Outlet } from "react-router-dom";
import './product.scss';

const Products = () => {
  return (
    <div className="products">
      <Outlet />
    </div>
  );
};

export default Products;
